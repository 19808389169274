.post-list {
  @include cleaner;
}

.post-list .post-name,
.post-grid .post-name,
h3.post-name {
  /* Sizes are modified for different number of columns */
  font-size: 1.3333em;
  line-height: 1.25em; /*16px 20px*/
}

.post-list .price-box.map-info a,
.post-grid .price-box.map-info a {
  margin-left: 3px;
}

/* POST image
-------------------------------------------------------------- */
/* POST alternative image */
.post-list .post-image .alt-img,
.post-grid .post-image .alt-img {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

/* Fix possible glitches during animation */
.post-list .post-image img,
.category-post-grid .post-image img /* Grid only in category view */
{
  -moz-transform: translateZ(0);
}

/* View type: list
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/
.post-list {
  &.list-mode {
	.item {
	  margin-bottom: 20px;
	}
  }

  &.grid-mode {
	.post-image-wrapper {
	  margin-bottom: 15px;
	}
  }

  .item {
	padding: 20px 0;
	margin-bottom: 20px;
	background-color: #fff;
	position: relative;
	box-sizing: border-box;

	@include trs(box-shadow 0ms ease-in, background 0s ease-out);
	@include cleaner;

	&.archived {
	  .labels-wrap {
		position: absolute;
		right: -3px;
		top: -3px;
		z-index: 1;
	  }

	  .archived-label {
		display: table-cell;
		vertical-align: middle;
		width: 60px;
		height: 60px;
		text-align: center;
		border-radius: 50%;
		transition: all .15s ease-out;
	  }
	}

	&:hover {
	  &.archived {
		.archived-label {
		  width: 65px;
		  height: 65px;
		}
	  }
	}
  }

  .right-column {
	float: right;
  }

  .post-name {
	margin: 0 0 15px;
	font-size: 1.625rem;
  }

  .post-image {
	display: block;
	position: relative;
	margin: 0 auto;
  }

  .price-box {
	margin: 0 0 15px;
	font-size: 1.3333em;
	line-height: 1.25em;
  }
  .ratings {
	margin: 10px 0;
  }
  .desc {
	margin: 15px 0;
  }
  .link-learn {
  }
  .btn-cart {
	width: 100%;
	max-width: 180px;
  }
  a.btn-cart {
	padding-left: 0;
	padding-right: 0;
  }
  .button span {
	float: none;
  }
  .availability {
	float: none;
	margin: 15px 0;
  }
  /* .add-to-links {
	margin: 15px 0 0;
  } */

  /* Hover effect
  -------------------------------------------------------------- */
  &.hover-effect {
	.item:hover {
	  z-index: 10;
	  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
	  border-color: transparent;
	  @include trs(box-shadow 400ms ease-out, background .4s ease-out);
	}
  }

  .show-post {
	clear: both;
	display: inline-block;
	vertical-align: top;

	span {
	  cursor: pointer;
	}
  }

  @include bp(max-width, $bp-tiny) {
	.item {
	  margin-bottom: 20px;
	}
  }

}

.category-blog {
  margin: 0 -1%;

  & + .show-all-wrap {
	text-align: center;

	.show-all-link {
	  font-size: 18px;
	  line-height: 24px;
	}
  }

  .post-list {
  	margin: 0;

  	$blog_indent: 12px;

  	.item {
  		margin: 70px 0 140px;
  		padding: 0;
  		display: flex;
  		@include cleaner;

  		.post-image-wrapper {
  			margin: 0;
  			width: 32.656%;
  			width: 435px;
  			position: relative;
  			//float: left;

  			a {
  				position: relative;
  				display: block;
  				z-index: 2;
  				text-align: center;
  			}

  			img {
  				border: none;
  			}

  			&:before {
				content: '';
				display: block;
				position: absolute;
				border: 1px solid rgba(0, 0, 0, 0.15);
				box-sizing: border-box;
				top: -$blog_indent;
				left: calc(#{$blog_indent} * 2);
				width: calc(100% - (#{$blog_indent} * 4));
				height: calc(100% + (#{$blog_indent} * 2));
			}
  		}

  		.post-shop {
  			//float: left;
  			width: 67.344%;
  			width: calc(100% - 435px);
  			box-sizing: border-box;
  			background: #fff;
  			padding: 3.003% 3.003% 3.003% 4.505%;
  			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  			transition: box-shadow 0.3s;

  			.manual_author_name {
  				display: none;
  			}

  			.post-name {
  				font-size: 22px;
				font-weight: 300;
				line-height: 1.27;
				color: $main-color;
  			}

  			.post_date {
  				width: 100%;
  				opacity: 0.4;
				font-size: 14px;
				font-weight: 300;
				line-height: 1.29;
				color: $main-color;
				margin-bottom: 30px;
  			}

  			.desc {
  				margin: 0 0 30px;
  				font-size: 13px;
				font-weight: normal;
				line-height: 1.85;
				color: $main-color;
				max-height: 120px;
				overflow: hidden;
  			}

  			.actions {
  				.link {
  					display: inline-block;

  					&:after {
  						@include icon;
  						content: $arrow_link;
  						font-size: 16px;
  						position: relative;
  						top: 2px;
  						left: 5px;
  					}
  				}
  			}
  		}

  		.no-touch & {
  			&:hover {
  				.post-shop {
  					box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  				}
  			}
  		}

  		@include bp(max-width, 890px){
	  		.post-image-wrapper {
	  			width: 50%;
	  		}

	  		.post-shop {
	  			width: 50%;

	  			.desc {
	  				margin: 0 0 20px;
					max-height: 70px;
	  			}
	  		}
  		}

  		@include bp(max-width, $bp-tiny){
  			flex-wrap: wrap;
  			padding: 10px;
  			margin: 50px 0 70px;
  			border: 1px solid rgba(0, 0, 0, 0.15);

	  		.post-image-wrapper {
	  			max-width: 435px;
	  			margin: 0 auto;

	  			&:before {
	  				display: none;
	  			}
	  		}

	  		.post-image-wrapper,
	  		.post-shop {
	  			width: 100%;
	  			box-shadow: none !important;
	  		}

	  		.post-shop {
	  			.desc {
					max-height: unset;
	  			}
	  		}
  		}
  	}
  }

  .pager {
  	border: none;

  	.amount,
  	.limiter {
  		display: none;
  	}
  }
}

.blog-section-title {
  margin-bottom: 20px;
}

.postscategory-list-container {
  margin: 0 0 60px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .postscategory-list-item {
  	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  	background: none;

  	a {
  		min-width: 200px;
  		display: block;
  		padding: 15px;
  		box-sizing: border-box;
  		position: relative;
  		text-transform: uppercase;
		font-size: 14px;
		font-weight: normal;
		line-height: 1.57;
		text-align: center;
		color: $main-color;

  		&:before {
  			content: '';
  			height: 4px;
  			width: 0;
  			position: absolute;
  			bottom: -1px;
  			left: 0;
  			transition: width 0.3s;
  		}
  	}

  	&:hover {
  		a {
  			&:before {
  				background: rgba(0, 0, 0, 0.1);
  			}
  		}
  	}

  	&.active {
  		a {
  			&:before {
  				background: #a9f2ff;
  			}
  		}
  	}

  	&:hover,
  	&.active {
  		background: none;

  		a {
  			&:before {
  				width: 100%;
  			}
  		}
  	}

  	@include bp(max-width, 890px){
  		a {
  			min-width: 150px;
  		}
  	}

  	@include bp(max-width, $bp-tiny){
  		width: 100%;
  	}
  }
}

.postscategory-title {
  h3 {
	padding-top: 7px;
	padding-bottom: 7px;
	border-bottom: 1px solid #e5e5e5;
	font-weight: normal;
	font-size: 1.5em;
	line-height: 1.2222em;
  }
}



#post-reviews {
  dt {
	text-align: right;
	font-style: italic;
	margin-bottom: 30px;
	padding: 0 0 15px;
	border-bottom: 2px solid #d8d8d8;
  }
}

.brander-unitopblog-postscategory-index {
  .sidebar {
	border: none;
	padding: 0;
	float: none;

	.postscategory-title {
	  display: none;
	}
  }

  .col-main {
  	float: none;
  }

  .page-title {
  	margin-bottom: 40px;

  	h1 {
	  	font-size: 28px;
		font-weight: 300;
		text-align: center;
		color: $main-color;
  	}
  }
}

.author_name {
  margin-bottom: 20px;
}

.post-shop-inner {
  .bullet {
	color: #b2a7b5;
	margin-bottom: 15px;
	@include cleaner;
  }

  dt {
	float: left;
	width: 150px;
	clear: left;
	font-size: 1rem;
  }

  dd {
	float: left;
	width: calc(100% - 150px);
  }

  .desc {
	font-size: 1rem;
	line-height: 1.625;
  }
}