$youtube: "\e900";
$vk: "\e901";
$user: "\e902";
$twitter: "\e903";
$star: "\e905";
$search: "\e906";
$play: "\e907";
$menu: "\e908";
$instagram: "\e909";
$heart: "\e90a";
$filter: "\e90b";
$facebook: "\e90c";
$compare: "\e90e";
$close: "\e90f";
$check: "\e910";
$cart: "\e911";
$arrow_right: "\e912";
$arrow_link: "\e913";
$arrow_down: "\e914";
$brander: "\e904";


.ic-youtube:before {
  content: $youtube;
}
.ic-vk:before {
  content: $vk;
}
.ic-user:before {
  content: $user;
}
.ic-twitter:before {
  content: $twitter;
}
.ic-star:before {
  content: $star;
}
.ic-search:before {
  content: $search !important;
}
.ic-play:before {
  content: $play;
}
.ic-menu:before {
  content: $menu;
}
.ic-instagram:before {
  content: $instagram;
}
.ic-heart:before {
  content: $heart;
}
.ic-filter:before {
  content: $filter;
}
.ic-facebook:before {
  content: $facebook;
}
.ic-compare:before {
  content: $compare;
}
.ic-close:before {
  content: $close;
}
.ic-check:before {
  content: $check;
}
.ic-cart:before {
  content: $cart;
}
.ic-arrow_right:before {
  content: $arrow_right;
}
.ic-arrow_link:before {
  content: $arrow_link;
}
.ic-arrow_down:before {
  content: $arrow_down;
}
.ic-brander:before {
  content: $brander;
}
