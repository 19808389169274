.cart {
  .product-image {
    border: 1px solid #e4e0e5;
    overflow: hidden;
    @include trs(border-color .3s ease);

    &:hover {
      border-color: $main-color;
    }
  }

  .link-wishlist,
  .link-edit {
    white-space: nowrap;
    color: $gray;

    &:hover {
      color: $main-color;
    }

    > * {
      display: inline-block;
      vertical-align: middle;
    }

    i {
      font-size: 1.125rem;
    }

    span {
      margin-left: 5px;

      @include bp(max-width, 991px) {
        display: none;
      }

      @include bp(max-width, $bp-tiny) {
        display: inline-block;
      }
    }
  }

  .btns-set {
    @include cleaner;
    padding: 35px 0;

    .checkout-types {
      @include cleaner;

      li {
        margin-bottom: 10px;

        a {
          font-style: italic;
          color: $main-color;

          &:hover {
            color: $dark;
          }
        }
      }

      @include bp(max-width, $bp-smallest) {
        float: none;

        button {
          width: 100%;
        }
      }
    }
  }

  .totals {
    .button {
      min-width: 225px;
      min-height: 48px;
      border-radius: 24px;
    }

    .btn-continue {
      @extend %btn-light;
      //min-width: 230px;
    }
  }
}

#shopping-cart-totals-table {
  width: 100%;
  font-size: 1rem;

  td {
    padding: 12px 5px;
  }

  tfoot {
    td {
      vertical-align: bottom;
    }

    .price {
      font-size: 21px;
    }
  }

  strong {
    font-weight: 500;
    color: $main-color;
  }

  .price {
    //margin-left: 20px;
  }
}

.btn-remove-round {
  font-size: 1.5rem;
  line-height: 1.75;
  display: inline-block;
  text-align: center;
  width: 40px;
  height: 40px;
  border: 1px solid #c4bec5;
  border-radius: 50%;
  color: #c4bec5;
  @include trs(color .3s ease, border-color .3s ease);

  &:hover {
    color: $main-color;
    border-color: $main-color;
  }
}

.cart-table-wrapper {
  padding: 30px 0;
  //background: #fafafa;

  .price {
    font-size: 16px;
    font-weight: 500;
    color: $main-color;
  }

  tr {
    td {
      padding: 30px 10px;
    }

    &:not(:first-child) {
      td {
        border-top: 1px solid rgba(0, 0, 0, 0.05);
      }
    }
  }

  .item-options {
    margin: 0;

    dt,
    dd {
      color: $main-color;
      font-size: 14px;
    }

    dt {
      background: none;
      padding: 0;
    }

    dd {
      font-style: normal;
      font-weight: 500;
    }
  }

  .btn-edit,
  .ic-heart {
    font-weight: 500;
  }

  .ic-heart {
    font-size: 32px;
  }

  .product-name {
    font-size: 18px;
    font-weight: 300;
    color: $main-color;
  }
}

%btn-update {
  border: none;
  background: none;
  font-size: 1rem;
  color: $gray;
  padding-left: 30px;
  position: relative;
  cursor: pointer;
  @include trs(color .3s ease);

  &:before {
    position: absolute;
    font-family: BaseIcons;
    content: '\e210';
    left: 0;
    top: 0;
    @include trs(transform .3s ease)
  }

  &:hover {
    color: $main-color;

    &:before {
      transform: rotate(180deg);
    }
  }
}

.cart-form-heading {
  @include cleaner;
  margin: 20px 0;

  .page-title {
    float: left;
    margin-bottom: 0;

    h1 {
      font-size: 28px;
      font-weight: 300;
      color: $main-color;
    }
  }

  .btn-update {
    @extend %btn-update;
    float: right;
    margin-top: 10px;
  }
}

//Discount

#discount-coupon-form {
  margin-bottom: 30px;
}

.discount {
  h2 {
    font-size: 1rem;
    margin-bottom: 0;
  }

  .discount-form {
    > label {
      margin-bottom: 15px;
      color: #8c838e;
    }
  }

  button {
    margin-bottom: 10px;
  }

  .input-box {
    white-space: nowrap;
    font-size: 0;
    position: relative;
    padding-bottom: 20px;
    max-width: 440px;

    > *:not(.validation-advice) {
      display: inline-block;
      vertical-align: top;
      //max-height: 38px;
      height: 48px;
    }

    .input-text {
      width: calc(100% - 145px);
      border-color: #a399a5;
    }

    .btn-use {
      @extend %btn-light;
      border-radius: 0 24px 24px 0;
      cursor: pointer;
      font-weight: 500;
    }

    .validation-advice {
      font-size: 12px;
      position: absolute;
      left: 0;
      bottom: -5px;
    }
  }
}