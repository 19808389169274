html {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  color: $main-color;
}

.container {
  width:100%;

}

.lte-ie8 .container { max-width:1496px;}


// GothamPro
@font-face {
  font-family: 'GothamPro';
  src: url(brander/fonts/GothamPro/GothamProRegular/GothamProRegular.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro';
  src: url(brander/fonts/GothamPro/GothamProItalic/GothamProItalic.ttf);
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'GothamPro';
  src: url(brander/fonts/GothamPro/GothamProLight/GothamProLight.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro';
  src: url(brander/fonts/GothamPro/GothamProLightItalic/GothamProLightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'GothamPro';
  src: url(brander/fonts/GothamPro/GothamProMedium/GothamProMedium.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro';
  src: url(brander/fonts/GothamPro/GothamProMediumItalic/GothamProMediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'GothamPro';
  src: url(brander/fonts/GothamPro/GothamProBold/GothamProBold.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro';
  src: url(brander/fonts/GothamPro/GothamProBoldItalic/GothamProBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'GothamPro';
  src: url(brander/fonts/GothamPro/GothamProBlack/GothamProBlack.ttf);
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro';
  src: url(brander/fonts/GothamPro/GothamProBlackItalic/GothamProBlackItalic.ttf);
  font-weight: 800;
  font-style: italic;
}


// Savoye LET
@font-face {
  font-family: 'Savoye LET';
  src: url(brander/fonts/SavoyeLET/SavoyeLET.ttf);
}





/* Desktop - design for a width of 1366px and 1360px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/


@media only screen and (min-width: 1496px)  {
  .container { max-width: 1496px; }
}


[data-show-btn],
[data-show-hide-block] {
  opacity: 0;
  transition: opacity 0.2s;

  &[data-loaded] {
    opacity: 1;
  }
}

[data-show-hide-block] {
  overflow: hidden;
  max-height: 175px;
  transition: max-height 0.3s;
  position: relative;

  &[data-disabled] {
    & + [data-show-btn] {
      display: none !important;
    }
  }
}
[data-show-btn] {
  cursor: pointer;
}

.text-block-wrapper {
  .text-block-inner {
    max-height: 180px;
    padding: 0;

    &:not([data-disabled]){
      overflow: auto;
    }

    &.open {
      overflow: hidden;
    }

    &::-webkit-scrollbar {
      width: 3px;

      &:horizontal {
        height: 3px;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.1);
      border: 1px solid #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #000;
      border-radius: 2px;

      &:hover {
        background-color: #000;
      }
    }

    li,
    p {
      font-size: 14px;
      font-weight: 300;
      color: $main-color;
    }

    a {
      color: $link;

      &:hover {
        color: $link_hover;
      }
    }

    p,
    ul,
    ol {
      margin-bottom: 15px;
    }

    > div {
      *:last-child {
        margin-bottom: 0;
      }
    }
  }


  .read-more {
    color: $link;
    font-size: 14px;
    line-height: 1.33;
    position: relative;
    margin-top: 25px;
    display: inline-block;
    cursor: pointer;
    padding-right: 20px;

    &:hover {
      color: $link_hover;
    }

    &:after {
      @include icon;
      content: $arrow_down;
      font-size: 16px;
      position: absolute;
      top: -2px;
      right: 0;
      transition: transform 0.3s;
    }

    &.open {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }
}




.ic-odnoklasniki,
.ic-odnoklassniki,
.ic-yandex,
.ic-moikrug,
.ic-mailru,
.ic-vk,
.ic-pinterest,
.ic-twitter,
.ic-facebook,
.ic-skype,
.ic-linkedin,
.ic-tumblr,
.ic-blogger,
.ic-flickr,
.ic-vimeo,
.ic-youtube,
.ic-renren,
.ic-instagram,
.ic-googleplus,
.ic-google {
  display: inline-block;
  vertical-align: top;
  line-height: 36px!important;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'SocIcons' !important;
}


select {
  -webkit-appearance: none;
  background: url(../images/arrow.png) no-repeat 95% 50%;
  background-size: 12px;
  padding-right: 30px;
}

textarea {
  resize: vertical;
  max-width: 100%;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

#root-wrapper {
  position: relative;
  overflow: hidden;
}

.wrapper {
  height: 100%;
}

//Sticky footer

.page {
  display: table;
  width: 100%;
  height: 100vh;
  table-layout: fixed;

  .not-full-width {
    .the-slideshow-wrapper {
      margin: 0; 
    }
  }

  .header-container {
    display: table-header-group;
    height: 1%;
  }

  .main-container {
    display: table-row;
    height: 1%;
  }

  .footer-container {
    display: table-footer-group;
    height: 1%;
  }
}

.main {
  height: 100%;
}

button {
  font-family: 'GothamPro';
}

%button {
  min-width: 150px;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  border: none;
  color: $main-color;
  background: #c9f7ff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 20px;
  @include trs(color .3s ease, background-color .3s ease, border-color .3s ease);

  &:hover {
    background: #97efff;
    color: $main-color;
  }

  &:focus {
    background: #97efff;
    color: $main-color;
  }
}

%btn-light {
  min-width: 150px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  padding: 0 20px;
  background: none;
  border: 1px solid #dedede;
  border-radius: 24px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);

  &:hover {
    color: $main-color;
    background: rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.05);
  }

  @include bp(max-width, 991px) {
    min-width: 130px;
  }
}

.button {
  @extend %button;
}

.btn-search,
.btn-subscribe,
.btn-use {
  border: none;
  min-width: 0;
  color: inherit;
  border-radius: 0;
  font-weight: normal;
  letter-spacing: 0;

  &:hover {
    color: $dark;
  }
}

.btn-remove,
.btn-remove2,
.map-popup-close,
.remember-me-popup-head .remember-me-popup-close {
  color: $main-color;
  line-height: 1;
}

.toolbar {
  /* .sort-by {
    .category-asc,
    .category-desc {
      border: 1px solid $border-color-light;
      width: 36px;
      height: 36px;
      line-height: 36px;
      color: $main-color;
      text-align: center;
      cursor: pointer;
    }
  }

  select,
  .chosen-container {
    margin-right: 22px;

    @include bp(max-width, $bp-smallest) {
      margin-right: 15px;

      & ~ button {
        margin-right: 15px;
      }
    }
  } */

  /* @include bp(max-width, $bp-tiny) {
    label {
      display: none;
    }
  } */

  .limiter {
    display: none;
  }

  .view-mode {
    button{
      cursor: pointer;
    }
    .ic {
      font-size: 20px;
      color: #dbd0db;
      @include trs(color .3s ease);
      border: 0;
      background: none;
    }

    > label {
      display: none;
    }
  }
}


.breadcrumbs {
  line-height: 18px;
  position: relative;
  margin: 30px 0;

  li {
    > a,
    > span {
      font-size: 13px;
      font-weight: 300;
      line-height: 1.38;
      color: $main-color;
    }

    > span {
      opacity: 0.5;
      cursor: default;
    }

    .sep {
      margin: -3px 12px 0 10px;

      &:before {
        content: '/';
        font-size: 16px;
      }
    }
  }
}

#sh_button {
  @include bp(max-width, $bp-tiny) {
    min-height: 0;

    .sh_title_text {
      display: none;
    }
  }
}


.touch {
  .control .checkbox {
    & + label {
      &:hover:after {
        opacity: 0;
        visibility: hidden;
      }
    }

    &:checked + label:after {
      opacity: 1;
      visibility: visible;
    }
  }
}

.ui-state-hover,
.ui-widget-content .ui-state-hover {
  border-color: #d3d3d3;
}


.link {
  color: $blue;

  &:hover {
    color: $link-hover;
  }
}

.sticker-wrapper {
  .sticker {
    width: auto;
    height: auto;
  }

  &.top-right {
    top: 10px;
    right: 10px;

    .sticker {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: #952828;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      box-sizing: border-box;
      border: 1px solid #dedede;
    }
  }

  &.top-left {
    top: 0;
    left: 0;
    overflow: hidden;

    .sticker {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5;
      color: $blue;
      position: relative;
      left: -5px;
      height: 40px;
      padding: 0 20px;
      border: 1px solid #dedede;
      box-sizing: border-box;
      border-bottom-right-radius: 10px;
      transform: skewX(-13deg);

      span {
        display: block;
        transform: skewX(13deg);
      }
    }
  }

  .sticker {
    &.bestseller {
      color: #00d280;
    }
  }
}

.the-slideshow {
  .item {
    img {
      width: 100%;
    }
  }
}

.section-title {
  font-size: 28px;
  font-weight: 300;
  text-align: center;
  color: $main-color;
  padding-bottom: 60px;
  margin: 0;

  &.padding-right {
    padding-right: 100px;
    padding-left: 100px;
  }
}

//owl carousel

.itemslider-holder {
  position: relative;

}

.owl-controls {
  .owl-buttons {
    position: absolute;
    right: 0;
    width: 96px;
    height: 40px;
    top: -96px;

    @include bp(max-width, $bp-tiny) {
      display: none;
    }

    .owl-prev,
    .owl-next {
      width: 40px;
      height: 40px;
      border: 1px solid #878787;
      border-radius: 50%;
      background: $white !important;
      color: $main-color !important;
      position: absolute;
      top: 0;
      display: flex !important;
      align-items: center;
      justify-content: center;
      font-size: 40px !important;
      transition: all 0.3s;

      &:before {
        @include icon;
        content: $arrow_right !important;
        position: static;
      }

      &:not(.disabled):hover {
        background-color: rgba(0, 0, 0, 0.05) !important;
        border-color: rgba(0, 0, 0, 0.05);
      }

      &.disabled {
        opacity: 0.3;
        cursor: default;
      }


      &.owl-prev {
        left: 0;
        transform: rotate(180deg);
      }

      &.owl-next {
        right: 0;
        transform: rotate(0deg);
      }
    }
  }

  .owl-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    width: 100%;

    .owl-page {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      width: 8px;
      height: 8px;

      span {
        width: 4px;
        height: 4px;
        display: inline-block;
        border-radius: 50%;
        transition: all 0.3s;
        opacity: 0.2;
      }

      &:hover,
      &.active {
        span {
          width: 8px;
          height: 8px;
          opacity: 1;
        }
      }
    }
  }
}

.itemslider-num {
  font-size: 20px;
  text-align: center;
  padding: 10px 0;

  span {
    padding: 0 30px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    min-width: 120px;
    box-sizing: border-box;

    &:before,
    &:after {
      position: absolute;
      font-family: BaseIcons;
      content: '\e260';
      font-size: 1.5em;
      top: 50%;
      left: 0;
      margin-top: -18px;
      transform: rotate(90deg);
    }

    &:after {
      left: auto;
      right: 0;
      transform: rotate(-90deg);
    }
  }

  @include bp(min-width, 784px) {
    display: none;
  }
}

.fl {
  float: left;
}

.fr {
  float: right;
}

//Loader

//div.layerednavigation-overlay {
  //background-color: #fff;
  //height: 100%;
  //left: 0;
  //opacity: 0.4;
  //position: fixed;
  //top: 0;
  //width: 100%;
  //z-index: 10000;

  //img {
  //  top: 100px;
  //  left: 45%;
  //  display: block;
  //  position: absolute;
  //}

  //div {
  //  position: absolute;
  //  left: 50%;
  //  top: 50%;
  //  width: 100px;
  //  height: 100px;
  //  margin-top: -50px;
  //  margin-left: -50px;
  //  background: url("../../../images/brander/layerednavigation/layerednavigation-overlay.gif") 50% 50% no-repeat;
  //}
//}

#uni-main-loader-checkout,
#sub-loader {
  position: fixed;
  z-index: 999;
}

.layerednavigation-overlay,
#sub-loader {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;

  &:after {
    position: fixed;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: .4;
    z-index: 10000;
  }
}

.sk-circle {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10001;
  opacity: .85;
}

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: $main-color;
  border-radius: 100%;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
  transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
  transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
  transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
  transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
  transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
  transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
  transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
  transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
  transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
  transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
  transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
  animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
  animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
  animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
  animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
  animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
  animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
  animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
  animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
  animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
  animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

//loader end
.product-options dd .chosen-container {
  &.chosen-container-multi {
    width: 100% !important;

    .chosen-choices {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      height: auto;
      
      .search-field {
        border: 1px solid #a399a5;
        display: block;
        padding: 9px;
        box-sizing: border-box;
        width: 100%;
        margin: 5px;

        input {
          display: block;
          margin: 0;
          width: 100%;
        }
      }
    }
  }
}


.chosen-container-single {
  .chosen-single {
    text-align: left;
    padding: 0 36px 0 10px;
    border-color: $border-color-light;

    div {
      width: 36px;
      height: 100%;
      top: 0;
      border-left: 1px solid $border-color-light;

      &:after {
        position: absolute;
        font-family: BaseIcons;
        content: "\e260";
        font-size: 20px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
      }

      b {
        display: none;
      }
    }
  }

  .chosen-results li,
  .chosen-drop {
    border-color: $border-color-light;
  }

  &.chosen-with-drop {
    .chosen-single {
      div {
        &:after {
          transform: rotate(180deg)
        }
      }
    }
  }
}

.sorter {
  .sort-by {
    .chosen-container {
      min-width: 212px;
    }
  }

  .amount {
    display: none;
  }
}

.limiter {
  .chosen-container {
    min-width: 76px;
  }
}

.faq-page {
  padding-bottom: 50px;
}

//Contacts
.contacts-wrap {
  clear: both;
  padding: 50px 0;
  @include cleaner;

  .buttons-set {
    p.required {
      margin: 0 0 10px;
      float: none;
    }

    .button {
      float: left;
      margin: 0;
    }
  }

  .contacts-form-holder {
    max-width: 580px;
    margin: 0 auto;
  }

  h2.legend,
  .feature .heading {
    font-family: 'PFSquareSansPro', Arial, sans-serif;
    font-weight: bold;
  }

  /* .contacts-map-holder {
    float: right;
  } */

  .feature,
  .phones {
    margin-bottom: 20px;
  }

  .field {
    margin-bottom: 8px;
  }

  .contacts-address {
    font-size: 1.4em;
    padding-top: 20px;
    text-align: center;
  }

  .fieldset {
    .legend {
      text-align: center;
      font-size: 23px;
    }
  }

  .form-list input.input-text,
  .field,
  #contactForm textarea {
    width: 100%;
    max-width: 100%;
    resize: vertical;
  }

  @include bp(max-width, 850px) {
    .buttons-set {
      .button {
        width: 100%;
      }
    }
  }

  .contacts-map-holder {
    width: 100%;
    margin-top: 50px;

    /* @include bp(max-width, 850px) {
      width: 100%;
      margin: 0 auto;
      float: none;

      #map {
        margin: 0 auto;
      }
    } */
  }
}

//checkboxes
.input-box {
  .checkbox,
  .radio {
    opacity: 0;
    width: 27px;
    height: 27px;
    position: relative;
    cursor: pointer;
    z-index: 2;

    & + .label {
      position: relative;
      margin-left: 0;
      padding-left: 36px;
      cursor: pointer;
      z-index: 1;

      label {
        cursor: pointer;
      }

      &:after {
        position: absolute;
        content: '';
        width: 25px;
        height: 25px;
        border: 1px solid #dadada;
        left: 0;
        top: 2px;
        border-radius: 3px;
      }

      &:before {
        display: block;
        border: none;
        position: absolute;
        font-family: BaseIcons;
        content: '\e607';
        line-height: 1;
        left: 6px;
        top: 8px;
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        @include trs(opacity .3s ease, visibility .3s ease);
      }
    }

    &:hover,
    &:checked {
      & + .label {
        &:before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.control {
  .checkbox {
    opacity: 0;
    display: none;

    & + label {
      position: relative;
      margin-left: 0;
      padding-left: 32px;
      padding-top: 4px;
      cursor: pointer;

      &:after {
        position: absolute;
        font-size: 12px;
        width: 23px;
        height: 23px;
        line-height: 1.9;
        text-align: center;
        z-index: 1;
        color: #fff;
        top: 0;
        left: 0;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: opacity .3s ease, visibility .3s ease;
        transition: opacity .3s ease, visibility .3s ease;
      }

      &:before {
        position: absolute;
        content: '';
        width: 21px;
        height: 21px;
        border: 1px solid #d7d1d1;
        top: 0;
        left: 0;
      }

    }

    & + label:hover,
    &:checked + label {
      &:after {
        opacity: 1;
        visibility: visible;
        background: $main-color;
      }
    }
  }
}

.webforms-callback {
  .callbacks-welcome-message {
    margin-bottom: 20px;
  }

  .validation-advice {
    padding-left: 90px;

    @include bp(max-width, $bp-smallest) {
      padding-left: 0;
    }
  }
}


@include bp(max-width, $bp-smallest) {
  .webforms-callback input.input-text {
    width: 100%;
  }

  .section-title.padding-right {
    padding-right: 0;
    padding-left: 0;
  }

  .itemgrid.itemgrid-adaptive .item {
    width: 98%;
  }
}

.fancybox-lock {
  #sh_button {
    right: 17px;
  }
}

.compare-table {
  .product-name {
    font-weight: bold;
  }

  td {
    vertical-align: top;
  }

  .link-wishlist {
    > * {
      display: inline-block;
      vertical-align: middle;
    }

    .ic-heart {
      margin-right: 5px;
    }
  }

  & + .buttons-set {
    margin-top: 5px;

    button {
      float: none;
    }
  }

  .availability {
    margin: 0;
  }
}


.social-links .social-list {
  padding: 0;
  font-size: 0;
  margin: 0;

  li {
    display: inline-block;
    padding: 0 8px;
    margin-bottom: 8px;

    a {
      display: block;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
    }

    [class*="ic-"] {
      margin: 0;
      width: 50px;
      height: 50px;
      font-size: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: #dcdcdc;
      transition: all 0.3s;
    }

    &:hover {
      [class*="ic-"] {
        border-color: #fff;
        background: #fff;
        box-shadow: 0 10px 30px 0 rgba(202, 202, 202, 0.7);
      }
    }
  }
}

.social-links {
  padding: 0;
  font-size: 0;
  margin: 0 -8px;

  a {
    display: inline-block;
    padding: 0 8px;
    margin-bottom: 8px;

    .ic {
      margin: 0;
      width: 50px;
      height: 50px;
      font-size: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: #dcdcdc;
      transition: all 0.3s;
    }

    &:hover {
      .ic {
        border-color: #fff;
        background: #fff;
        box-shadow: 0 10px 30px 0 rgba(202, 202, 202, 0.7);
      }
    }
  }
}

.data-table {
  .even {
    //background: #f8f1f0;
  }
}

.cart-table-wrapper {
  .data-table {
    .even {
      //background: #fafafa;
    }
  }
}

#scroll-to-top {
  transform: rotate(-90deg);
  background: #fff;
  font-size: 44px;
  box-shadow: 0 0 30px 0 rgba(202, 202, 202, 0.7);
  @include trs(color .3s ease);

  &:hover {
    color: $link;
    background: #fff;
  }

  &:before {
    content: $arrow_right;
  }
}

.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

@media only screen and (max-width: 959px) and (min-width: 768px) {
  .data-table th, .data-table td {
    padding: 5px;
    font-size: 12px;
  }
}

.rating-box {
  width: auto;
  height: 16px;
  position: relative;
  background: none;
  color: #dedede;

  &:before {
    @include icon;
    content: $star  $star  $star  $star  $star;
    font-size: 20px;
    display: block;
  }

  .rating {
    height: 16px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: none;
    color: #ff7d36;
    overflow: hidden;

    &:before {
      @include icon;
      content: $star  $star  $star  $star  $star;
      font-size: 20px;
      display: block;
    }
  }
}

//Brands

.brands-tabs {
  font-size: 0;
  margin-bottom: 40px;
  overflow: hidden;

  .horizontal {
    @include cleaner;
    font-size: 1rem;
    margin-bottom: 40px;
    border-top: 1px solid #ebebeb;
    padding: 20px 0;
    float: left;
    width: calc(100% - 80px);
    position: relative;

    &:after {
      position: absolute;
      content: '';
      width: 200%;
      height: 1px;
      background: #ebebeb;
      left: -100%;
      bottom: 0;
    }

    li {
      float: left;
      margin-right: 10px;

      &.active {
        a {
          color: $main-color;
          text-shadow: 0 0 0 $main-color;
        }
      }
    }
  }

  .link-all {
    font-size: 1rem;
    float: left;
    border-top: 1px solid #ebebeb;
    padding: 20px 0;
    width: 80px;
    margin-bottom: 40px;
  }

  [id^='brand-'] {
    display: inline-block;
    vertical-align: top;
    width: 20%;
    padding-right: 10px;
    box-sizing: border-box;

    @include bp(max-width, $bp-tiny) {
      width: 25%;
    }

    @include bp(max-width, 640px) {
      width: 33.33%;
    }

    @include bp(max-width, $bp-smallest) {
      width: 50%;
    }
  }

  dl {
    white-space: normal;
    font-size: 1rem;
    margin-bottom: 20px;

    dt {
      font-size: 30px;
      text-transform: uppercase;
      margin: 0 0 29px;
    }

    .bare-list {
      li {
        margin: 0 0 7px;
      }
    }
  }
}

//fancybox

.fancybox-overlay {
  background: rgba(208, 225, 248, 0.85);
}

.fancybox-skin {
  background: #fff;
  box-shadow: 0 5px 0 0 rgba(0, 0, 0, 0.05);
  border-radius: 0;
}

.fancybox-close {
  width: 26px;
  height: 26px;
  top: 20px;
  right: 20px;
  background: none;
  color: $main-color;

  &:hover {
    color: #7d7d7d;
  }

  &:before {
    font-family: 'BaseIcons';
    font-size: 26px;
    line-height: 1;
    content: "\e20f";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
  }
}

.webforms-callback {
  padding: 40px;
  box-sizing: border-box;

  @include bp(min-width, 768px) {
    width: 510px;
  }

  h2, .callbacks-welcome-message {
    color: $main-color;
    text-align: center;
    //font-family: 'Open Sans', arial;
    margin: 0;
  }

  h2 {
    font-size: 36px;
    margin-bottom: 10px;
  }

  .callbacks-welcome-message {
    font-size: 14px;
    margin-bottom: 40px;

    @include bp(max-width, $bp-tiny) {
      margin-bottom: 20px;
    }
  }

  .buttons-set {
    padding-top: 37px;
  }

  .field {
    label {
      display: none;
    }

    .validation-advice {
      padding: 0;
      text-align: center;
      width: 264px;
      max-width: 100%;
      margin: 5px auto 0;
      color: #f68d91;
    }

    input {
      height: 44px;
      width: 264px;
      max-width: 100%;
      display: block;
      margin: 0 auto;
      border-width: 0 0 1px;
      font-size: 14px;
      color: rgba(66, 83, 106, 0.6);

      &:focus {
        box-shadow: none !important;
      }

      &.validation-failed {
        border-color: #ed1c24 !important;
      }
    }
  }

  .buttons-set {
    .button {
      display: block;
      margin: 0 auto;
      float: none;
    }
  }
}

.product-counter {
  .spinner:not(.ui-spinner-input) {
    opacity: 0;
  }
}

.ratings{
  min-height: 16px;
  .rating-box{
    width: 90px;
    height: 12px!important;
    background-size: 19px!important;
    .rating {
      height: 12px!important;
      background-size: 19px!important;
      background-position-y: bottom;
    }
  }
  .rating-links{
    span.separator{
      display: none;
    }
    a{
      &:last-child{
        display: none;
      }
    }
  }
}

.products-grid{
  .add-to-links{
    li{
      button{
        border: 0;
        background: white;
        cursor: pointer;
      }
    }
  }
}

.products-grid, .products-list{
  .item-inner{
    &.stock{
      opacity: .3;
      &:hover{
        opacity: 1;

      }
    }
  }
}


.add-to-links {
  padding: 0 5px 0 0;

  li {
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid rgba(204, 204, 204, 0.6) !important;
      background: #fff !important;
      color: rgba(204, 204, 204, 0.6);
      box-sizing: border-box;
      font-size: 28px;
      //transition: all 0.3s;
      
      &, * {
        transition: none !important;
      }

      &:before {
        display: block;
        width: 100%;
        text-align: center;
      }

      &.ic-heart {
        &:before {
          margin-top: 2px;
        }
      }

      &:hover {
        border: 1px solid rgba(204, 204, 204, 0.25) !important;
        background: rgba(204, 204, 204, 0.25) !important;
      }

      &:active {
        border: 1px solid rgba(204, 204, 204, 0.6) !important;
        background: #fff !important;
        color: $main-color !important;
      }
    }
  }
}

.itemslider {
  margin-bottom: 100px;

  &.products-grid {
    .owl-wrapper {
      .owl-item {
        padding-bottom: 1px;
        
        .item {
          height: 100%;
        }
      }
    }

    .owl-controls {
      .owl-pagination {
        padding-top: 60px;
      }
    }

    .item {
      display: flex;
      border: 1px solid rgba(222, 222, 222, 1);

      .item-inner {
        width: 100%;
      }

      .no-touch & {
        position: relative;

        &:hover {
          z-index: 10;
          border-color: $blue;
        }
      }

      .product-image-wrapper {
        width: 100%;
        position: relative;
        background: #fff;
        padding-top: 30px;
      }

      .product-name {
        font-size: 14px;
        font-weight: 300;
        color: $main-color;
        text-align: center;
        line-height: 18px;
        height: 36px;
        overflow: hidden;

        a {
          color: $main-color;

          &:hover {
            color: #333;
          }
        }
      }

      .price-box {
        min-height: unset;
        margin: 0;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 15px 15px 30px;

        .regular-price,
        .special-price,
        .old-price {
          margin: 0 5px;

          .price {
            font-size: 14px;
            line-height: 1;
            color: $main-color;
          }
        }

        .regular-price,
        .special-price {
          .price {
            font-weight: 500;
          }
        }

        .old-price {
          .price {
            opacity: 0.3;
          }
        }
      }
    }
  }

  &.news-grid {
    margin-bottom: 100px;

    $blog_indent: 12px;

    .owl-controls {
      .owl-pagination {
        padding-top: 50px;
      }
    }

    .owl-item {
      padding: 15px 0;
    }

    .item {
      position: relative;
      box-sizing: border-box;

      &:before {
        content: '';
        display: block;
        position: absolute;
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        top: -$blog_indent;
        left: calc(#{$blog_indent} * 2);
        width: calc(100% - (#{$blog_indent} * 4));
        height: calc(100% + (#{$blog_indent} * 2));
      }

      .product-image {
        display: block;
        position: relative;
      }

      .blog-desc {
        display: none;
      }

      .blog-name {
        position: absolute;
        top: 0;
        left: 0;
        padding: 11.9541% 11.495% 20px;
        box-sizing: border-box;
        width: 100%;
        max-width: 270px;
        
        a {
          font-size: 20px;
          font-weight: 300;
          color: $main-color;
          line-height: 28px;
          letter-spacing: 1.1px;
        }
      }

      .post-date {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 20px 11.495% 11.265%;
        font-size: 14px;
        color: #918b79;
      }
    }
  }
}

.collection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(100% + 24px);
  margin: 0 -12px;
  padding-bottom: 70px;

  li {
    margin: 0 12px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .item {
      .info-block {
        position: absolute;
        left: 70px;
        width: calc(100% - 120px);
      }

      .title {
        display: block;
        font-size: 28px;
        font-weight: 300;
        line-height: 1.21;
        color: $main-color;
        margin-bottom: 25px;
      }

      .link {
        display: inline-block;

        &:after {
          @include icon;
          content: $arrow_link;
          font-size: 16px;
          position: relative;
          top: 2px;
          left: 5px;
        }
      }
    }

    &:nth-child(1),
    &:nth-child(3n+4) {
      width: 100%;

      .info-block {
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &:nth-child(2),
    &:nth-child(3n+5) {
      width: calc(37.059% - 24px);

      .info-block {
        bottom: 70px;
      }
    }

    &:nth-child(3),
    &:nth-child(3n+6) {
      width: calc(62.941% - 24px);

      .info-block {
        bottom: 70px;
      }
    }

    @include bp(max-width, 1100px){
      .item {
        .info-block {
          left: 40px;
          width: calc(100% - 80px);
        }
      }
    }

    @include bp(max-width, 960px){
      .item {
        .info-block {
          left: 30px;
          width: calc(100% - 60px);

          .title {
            font-size: 22px;
          }
        }
      }
    }

    @include bp(max-width, $bp-tiny){
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(3n+5),
      &:nth-child(3n+6) {
        width: 100%;

        .info-block {
          bottom: auto;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

  }
}

.page-title {
  h1 {
    font-size: 28px;
    font-weight: 300;
    color: $main-color;
  }
}

.textpagetype {
  .page-title {
    margin-bottom: 70px;

    @include bp(max-width, $bp-tiny){
      margin-bottom: 40px;
    }

    h1 {
      text-align: center;
      font-size: 28px;
      font-weight: 300;
      line-height: 1.14;
      color: $main-color;
    }
  }
}

.text-page-block {
  $indent: 20px;

  .text-page-item {
    margin-bottom: 180px;
    position: relative;

    &:not(.slider),
    .slider-item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }

    .title {
      font-size: 28px;
      font-weight: 300;
      line-height: 1.14;
      color: $main-color;
      margin-bottom: 40px;
    }

    .banner-text,
    .content-text {
      font-size: 14px;
      font-weight: normal;
      line-height: 1.57;
      color: $main-color;

      p {
        margin-bottom: 25px;
      }

      ul {
        margin-bottom: 40px;
      }

      li {
        position: relative;
        font-size: 14px;
        font-weight: 500;
        color: $main-color;
        padding-left: 48px;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          width: 28px;
          height: 1px;
          background: $main-color;
          opacity: 0.3;
        }
      }

      > *:last-child {
        margin-bottom: 10px;
      }
    }

    .info-block {
      box-sizing: border-box;
    }

    .image-block {
      position: relative;
      display: inline-block;
      max-width: 942px;

      img {
        position: relative;
        z-index: 2;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        top: -$indent;
        left: calc(#{$indent} * 2);
        width: calc(100% - (#{$indent} * 4));
        height: calc(100% + (#{$indent} * 2));
      }
    }

    .youtube {
      width: 100%;
      text-align: center;

      .video-block {
        display: inline-block;
        position: relative;

        iframe {
          display: block;
          position: relative;
          z-index: 2;
          border: none;
          max-width: 100%;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          border: 1px solid rgba(0, 0, 0, 0.15);
          box-sizing: border-box;
          top: calc(#{$indent} * 1.5);
          left: -$indent;
          width: calc(100% + (#{$indent} * 2));
          height: calc(100% - (#{$indent} * 3));
        }
      }
    }

    &.big-banner {
      justify-content: flex-end;

      &:before {
        content: '';
        position: absolute;
        width: 340px;
        height: 1px;
        background: rgba(0, 0, 0, 0.2);
        top: -1.7%;
        left: 2.451%;
        transform: rotate(-45deg) translate(-50%);
      }

      .info-block {
        position: absolute;
        z-index: 3;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 505px;
        padding: 70px;
        background: #fff;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
      }

      .title {
        font-size: 36px;
        font-weight: 300;
        color: $main-color;
        margin-bottom: 45px;
      }
    }

    &.slider {
      .slider-item {
        padding: $indent 0;

        &:not(:first-child) {
          display: none;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          border: 1px solid rgba(0, 0, 0, 0.15);
          box-sizing: border-box;
          top: 0;
          right: 0;
          width: 78.561%;
          height: 100%;
        }

        .image-block,
        .info-block {
          position: relative;
          z-index: 2;
        }
      }

      .owl-item {
        .slider-item {
          &, &:not(:first-child) {
            display: flex;
          }
        }
      }

      .owl-controls {
        padding-top: 50px;
      }

      .owl-buttons {
        position: static;
        width: 100%;
        height: auto;

        div {
          width: 46px;
          height: 46px;
          margin-top: -23px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fafafa !important;
          border-radius: 0;
          border: none;
          top: 50%;

          &.owl-prev {
            left: 0;
          }

          &.owl-next {
            right: 0;
          }

          &:before {
            display: block;
            font-size: 40px;
            color: #231f20;
          }

          &:not(.disabled):hover {
            background-color: rgba(255, 255, 255, 1) !important;
          }
        }
      }

      .image-block {
        float: left;
        width: 36.295%;

        &:before {
          display: none;
        }
      }

      .info-block {
        float: right;
        width: calc(63.705% - 6.126%);
        padding-right: 6.126%;
      }
    }

    &:not(.big-banner):not(.slider) {
      .image-block {
        width: 40.276%;
      }

      .info-block {
        width: calc(59.724% - 6.126%);
      }
    }

    &.slider,
    &:not(.big-banner):not(.slider) {
      .info-block {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
    }

    @include bp(max-width, 1100px){
      .banner-text,
      .content-text {
        ul {
          margin-bottom: 25px;
        }
      }

      &.big-banner {
        &:before {
          display: none;
        }

        .info-block {
          padding: 40px;
        }

        .title {
          font-size: 28px;
          margin-bottom: 30px;
        }
      }

      .youtube {
        .video-block {
          iframe {
            width: 600px;
            height: 336px;
          }
        }
      }
    }

    @include bp(max-width, $bp-tiny){
      border: 1px solid rgba(0, 0, 0, 0.15);
      margin-bottom: 50px;

      .image-block {
        &:before {
          display: none;
        }
      }

      &.big-banner {
        .info-block {
          position: static;
          transform: none;
          width: 100%;
          padding: 30px;
          box-shadow: none;
        }
      }

      &.video {
        border: none;
      }

      &.slider {
        border: none;

        .owl-item {
          .slider-item {
            border: 1px solid rgba(0, 0, 0, 0.15);
            padding: 0;

            &:before {
              display: none;
            }
          }
        }
      }

      &.slider,
      &:not(.big-banner):not(.slider) {
        .image-block {
          width: 100%;
          order: 0;
          text-align: center;
        }

        .info-block {
          width: 100%;
          order: 1;
          padding: 30px;
        }
      }


      .youtube {
        .video-block {
          iframe {
            width: 450px;
            height: 252px;
          }

          &:before {
            display: none;
          }
        }
      }
    }

    @include bp(max-width, 500px){
      &.big-banner {
        .info-block {
          padding: 20px 15px;
        }
      }

      &.slider,
      &:not(.big-banner):not(.slider) {
        .info-block {
          padding: 20px 15px;
        }
      }

      .youtube {
        .video-block {
          iframe {
            width: 300px;
            height: 168px;
          }

          &:before {
            display: none;
          }
        }
      }
    }

  }
}

.cities-list-container {
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 40px;
  }

  .city-list-item {
    margin: 0 5px 10px;
    border: 2px solid transparent;
    box-sizing: border-box;

    &.active {
      border-color: #bebdbc;
    }
    
    a {
      display: block;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: 300;
      color: $main-color;
      cursor: pointer;

      &:hover {
        color: #bebdbc;
      }
    }
  }
}

#map {
  margin-bottom: 30px;
}

#contacts-info {
  .image-holder-block {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;

    &:not(:first-child){
      border-top: 1px solid #bebdbc;
    }

    &:nth-child(even){
      flex-direction: row-reverse;
    }

    .image-holder {
      max-width: 50%;
    }

    .info,
    [class*="info_"] {
      width: 50%;
    }

    .title,
    .value {
      float: left;
      font-size: 16px;
      color: $main-color;
    }

    .title {
      width: 150px;
      clear: left;
      font-weight: 300;
    }

    .value {
      width: calc(100% - 150px);
      font-weight: 500;
    }

    .address,
    .phone,
    .time {
      margin-bottom: 15px;
      @include cleaner;
    }

    .address {}
    .phone {}
    .time {}
  }
}