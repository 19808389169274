.page-banners {
  .banner {
	a {
	  img {
		width: 100%;
	  }
	}
  }
}

/* .homepage-pagetext {
  .section-title {
	margin-bottom: 20px;
  }
} */
