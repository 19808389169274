.header-container {
  .header-primary-container {
    background-image: linear-gradient(180deg, #f7f7f7 0, #fff 35px);
  }

  &.header-regular {
    .header-top-container {
      .right-column {
        a {
          color: $main-color;
          text-transform: capitalize;

          &:hover {
            color: #7d7d7d;
          }
        }
      }
    }

    .nav-container {
      .inner-container {
        .mobnav-trigger,
        .nav-regular {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .nav-regular {
    > .nav-item.first {
      position: relative;

      > a {
        &:before {
          @include icon;
          content: $menu;
          font-size: 36px;
          left: 2px;
          top: -1px;
        }
      }

      @include bp(min-width, 800px) {
        box-sizing: border-box;

        > a {
          padding-left: 65px;
          position: relative;

          > .caret {
            display: none;
          }

          @include bp(max-width, 1024px) {
            &:after {
              left: 15px;
            }
          }
        }

        > ul {
          width: 100%;
        }
      }
    }
  }

  #sticky-catalog-menu {
    > a.level-top {
      padding-left: 40px;

      /* &:before {
        left: 10px;
      } */
    }

    .sticky-main-nav {
      li {
        a {
          margin: 0 !important;

          &:after {
            display: none;
          }

          &:hover {
            color: $main-color;
          }
        }
      }
    }
  }

  .lang-switcher {
    &.dropdown {
      font-size: 14px;
      color: $main-color;

      a {
        &:hover {
          color: rgba(0, 0, 0, 0.4);
        }
      }

      .dropdown-heading {
        line-height: 22px;

        > span {
          padding: 10px 20px;
        }
      }

      .dropdown-content {
        min-width: 100%;
        box-sizing: border-box;
        line-height: 22px;
        padding: 0 20px;

        li {
          padding: 10px 0;
          border-top: 1px solid #e5e5e5;
        }

        .flag {
          background-position: 0 3px;
        }

        .current {
          //opacity: .5;
          display: none;
        }
      }
    }

    .flag {
      background-repeat: no-repeat;
      background-position: 0 10px;
    }
  }

  .header-contacts {
    font-size: 14px;
    color: $main-color;

    a {
      cursor: pointer;

      &:hover {
        color: rgba(0, 0, 0, 0.4) !important;

        span {
          color: inherit !important;
        }
      }
    }

    .dropdown-heading {
      max-height: unset;
      margin-top: -12px;
      line-height: 1;

      .value {
        line-height: 1;
      }

      > span {
        line-height: 22px;
        display: block;
        box-sizing: border-box;
        padding: 20px 20px 10px;
      }
    }

    > span {
      padding: 0 10px;
    }

    .dropdown-content {
      padding: 0 20px 10px;
      min-width: 100%;
      box-sizing: border-box;

      @include bp(max-width, $bp-tiny){
        padding-top: 0;
      }

      li {
        white-space: normal;
        clear: both;
        line-height: 22px;
        min-height: unset;
        padding-bottom: 10px;
      }

      .operator-icon {
        display: none;
      }

      .content {
        display: block;
        overflow: hidden;
      }
    }
  }

  .callback-header {
    margin-right: 0;
  }

  &.header-mobile {
    .header-primary-container {
      padding-top: 10px;

      .logo--mobile {
        margin-bottom: 15px;
      }
    }
  }
}

.dropdown-content {
  .block-subtitle,
  .block-subtitle {
    font-size: 18px;
    font-weight: 500;
  }
}

.callback-header {
  .callback-title {
    display: block;
  }
}

.header-regular {
  #mini-cart-label {
    display: none;
  }

  .skip-link {
    line-height: 50px !important;
    height: 50px;

    > span {
      width: 30px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: relative;

      .count {
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
        font-size: 10px;
        font-weight: bold;
        padding: 6px;
        line-height: 0.6 !important;
        text-align: center;
        color: $main-color;
      }

      .ic {
        font-size: 30px;
        color: #bebdbc;

        &.ic-cart {
          font-size: 46px;
        }

        &.ic-user {
          font-size: 20px;
        }
      }
    }

    &.skip-account {
      > span {
        width: auto;
      }
    }
  }

  .is-not-empty {
    .skip-link {
      &.skip-account {
        height: auto;
        overflow: hidden;

        > span {
          line-height: 40px !important;
        }
        .label {
          font-size: 14px;
        }

        .ic {
          margin-right: 5px;

          &:before {
            font-size: 16px;
            position: relative;
            top: -4px;
          }
        }
      }
    }
  }
}

.links-wrapper-separators .links > li {
  > a {
    background: none;

    &.current {
      position: relative;
      color: $main-color;

      &:after {
        content: '';
        width: 100%;
        height: 4px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: $blue_light;
      }
    }
  }
}

.user-menu {
  [id$="-wrapper-regular"] {
    float: right;

    @include bp(max-width, 769px) {
      float: none;
    }
  }

  .dropdown {
    .dropdown-heading {
      font-size: 1.125rem;
      max-height: none;

      .caret {
        display: none;
      }
    }
  }
}

.dropdown {
  &.is-not-empty {
    .dropdown-heading {
      .ic {
        color: $main-color;
      }
      /* span {
        color: $main-color;
      } */
    }
  }


  .btn-remove {
    font-size: 1.3rem;
    margin-top: -1px;
  }
}

#nav {
  .dropdown {
    .btn-remove {
      height: 50px;
    }
  }
}

.mini-cart {
  .mini-cart-content {
    .actions {
      button {
        padding: 10px 5px;

        &:last-child {
          float: right;
          margin-right: 0;
        }
      }
    }

    .btn-edit {
      font-size: 0;

      &:before {
        font-size: 14px;
      }
    }
  }
}

.header-mobile {
  .skip-link {
    position: relative;
    float: left;
    width: 20%;
    text-align: center;
    cursor: pointer;
    height: 50px;
    display: flex;
    justify-content: center;

    @include bp(max-width, $bp-tiny){
      font-size: 13px;
      padding: 0 5px;
      box-sizing: border-box;
      white-space: nowrap;
      background-color: #fff;
      color: #bebdbc;
      border-style: solid;
      border-color: #e6e6e6;
      border-width: 1px 0;

      &.not-empty {
        color: $main-color;
      }

      #mini-cart-heading {
        display: flex;
      }

      .count {
        color: $main-color;
        width: auto;
        min-width: 5px;
        margin-right: 5px;
        height: 20px;
        line-height: 20px;
        margin-left: -5px;
        font-size: 11px;
        font-weight: bold;
      }

      .icon {
        font-size: 34px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.ic-search,
        &.ic-user {
          font-size: 18px;
        }

        &.ic-menu {
          font-size: 44px;
          color: $main-color;
        }

        &.ic-cart {
          font-size: 48px;
          width: 30px;
        }

        &:after {
          margin: 0;
        }
      }

      &.skip-active {
        background: #f7f7f7;

        .count {
          color: $main-color;
        }
      }

      &:hover {
        //color: $main-color !important;

        &.skip-active {
          //color: #fff !important;
        }
      }
    }

    span {
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      line-height: 50px;
      margin: 0 3px;

      &:after {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -0.25em;
      }
    }

    /* .icon {
      margin-right: 3px;
      font-size: 16px;
    } */
    .caret {
      display: none;
    }
  }

  #mini-cart {
    &.is-not-empty {
      @include bp(max-width, $bp-tiny){
        .skip-link {
          color: $main-color;
        }
      }
    }
  }

  a.skip-link {
    text-decoration: none;
  }

  .header {
    width: 100%;

    > .inner-container {
      padding: 0;
    }
  }

  .header-top {
    .hp-block {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    .callback-header {
      position: static;
      float: right;
      margin-top: 0;
    }
  }

  /* #search_mini_form {
    .input-text,
    .button {
      color: $white;
    }
  } */

  .logo-wrapper--mobile {
    .logo {
      max-width: 200px;
      margin: 0 auto 35px;
    }
  }

  #header-nav {
    .nav {
      width: 100%;
    }
  }

  .user-menu {
    border: none;
  }

  .container_header_primary_left_1 {
    display: block;
  }

  @include bp(max-width, 769px) {
    .hp-block {
      .item-right {
        float: none;
        margin-left: 0;
        text-align: left;
      }
    }
  }
}

// SEARCH AUTOCOMPLETE
.search-wrapper {
  .reset-button {
    display: none;
  }

  #search_mini_form {
    @include cleaner;

    .form-search {
      margin: 7px 0;
    }

    .input-text {
      font-size: 13px;
      border-width: 0 0 1px;
      border-radius: 0;
      background: none;
      @include trs(color .15s ease-in-out);

      @include bp(min-width, 1500px) {
        width: 244px;
      }

      @include bp(max-width, 800px) {
        width: 180px;
      }

      @include bp(max-width, 769px) {
        width: 100%;
      }

      & + button {
        font-size: 16px;
        color: #bebdbc;

        &:before {
          content: $search;
          font-family: 'diamant';
        }
      }

      &:hover,
      &:focus {
        & + button {
          color: $main-color;
        }
      }
    }
  }

  .searchautocomplete-search {
    border: 1px solid #d5d5d5;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
    padding: 10px;
    right: 0;
    text-align: center;

    .s_item {
      @include cleaner;
      @include trs(border-color .3s ease);
      margin-bottom: 20px;
      border-bottom: 1px solid #333;

      &:hover {
        border-color: $main-color;
      }

      &:last-child {
        margin-bottom: 10px;
      }
    }

    .s_icon {
      margin-bottom: 10px;
    }

    .s_details {
      padding-bottom: 10px;
      box-sizing: border-box;
    }

    .s_description {
      display: none;
    }

    a {
      display: inline-block;

      .s_item_name {
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 10px;
        @include trs(color .3s ease);
      }

      &:hover {
        .s_item_name {
          color: $dark;
        }
      }
    }
  }
}

.search-autocomplete {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);

  @include bp(max-width, $bp-tiny) {
    right: 0;
  }

  .search-more {
    font-style: italic;
  }
}

#mini-account {
  .mini-account-content {
    padding: 0;
    text-align: center;
  }

  .caret {
    display: none;
  }
}

[id^="mini-account"] {
  > a {
    display: inline-block;
    vertical-align: middle;
    padding-right: 12px;

    .icon {
      &:before {
        font-size: 16px;
        position: relative;
        top: 1px;
      }
    }
  }
}

.sticky-header {
  #search_autocomplete {
    display: none;
  }

  .touch & {
    #header-nav {
      display: none;
    }
  }
}

.mini-cart{
  .mini-cart-content{
    .actions{
      /* display: flex;
      justify-content: space-between; */

      button{
        min-width: 140px;
        height: 43px;
        line-height: 1;
        padding: 0 5px;
        width: 45%;
      }
    }
  }
}

.mini-wishlist {
  .mini-wishlist-content {
    .actions {
      text-align: center;

      button {
        float: none;
      }
    }
  }
}