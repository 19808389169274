.fancybox-lock {
  .checkout-onepage-index {
    margin-right: 0;
  }
}

.checkout-onepage-index {
  margin-right: 0;

  .main {
    padding-bottom: 100px;
  }

  @include bp(max-width, $bp-tiny){
    .header-primary {
      padding-top: 0;
    }

    .callback-header {
      float: right;
    }
  }
}

.uni-wrapperIn {
  position: relative;
  padding: 35px 0 45px;

  .checkout-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: $light;

    .ico-down-arrow {
      display: none;
      vertical-align: middle;
      font-size: 8px;
      color: #a3a3a3;
      margin-left: 10px;
      transition: transform .2s ease-out;
    }

    &.active {
      .ico-down-arrow {
        transform: rotate(180deg);
      }
    }
  }

  @include bp(max-width, $bp-small) {
    padding-bottom: 0;

    .checkout-title {
      font-size: 21px;
    }
  }
}

.login-form {
  @include cleaner;
  margin-bottom: 20px;

  .actions-set {
    .buttons-set {
      text-align: right;
    }
  }
}

.checkout-billing {
  float: left;
  width: 41%;
  @include cleaner;

  .shipping-note {
    margin-bottom: 30px;
    color: #ccc;
    font-size: 18px;
  }

  .checkout-end {
    .btn-checkout {
      width: 250px;
      height: 48px;
      border-radius: 24px;

      @include bp(max-width, $bp-smallest) {
        width: 100%;
      }
    }

    .opcheckout-newsletter-subscribe {
      margin-bottom: 20px;
    }

    .btn-red {
      width: 100%;
      margin-bottom: 25px;
    }

    .agree-link {
      color: #959595;

      .text {
        font-size: 14px;
        line-height: $lh-smallest;
        border-bottom: 1px dashed #959595;
        transition: border-color .2s ease-out;
      }

      .no-touch &:hover {
        color: #ddd;

        .text {
          border-color: #ddd;
        }
      }

      .wrapper &:active {
        color: #ccc;

        .text {
          border-color: #ccc;
        }
      }
    }

    .opcheckout-newsletter-subscribe {
      color: #000;
      font-size: 16px;
      line-height: $lh-small;
    }

    span.checkbox-emu {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      width: 35px;
      height: 35px;
      margin-right: 15px;
      color: #acacac;
      border-radius: 2px;
      border: 1px solid #acacac;

      &:before {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -6px;
        margin-left: -6px;
        font-size: 12px;
      }

      &.active {
        &:before {
          display: block;
        }
      }
    }

    .custom-checkbox {
      cursor: pointer;

      .text {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .opener-wrap {
    float: right;
    width: 67.5%;

    .ico-down-arrow {
      margin-left: 10px;

      &:before {
        display: inline-block;
        font-size: 8px;
        color: #a3a3a3;
        transition: transform .2s ease-out;
      }
    }
  }

  .opcheckout-comment {
    @include cleaner;
    margin-bottom: 30px;

    .text-form {
      overflow: visible;
    }

    .form-wrap {
      clear: both;

      .textarea-set {
        margin-bottom: 10px;
      }

      .comment-wrap {
        margin-bottom: 20px;
      }
    }

    .opener-wrap {
      float: none;
    }

    textarea {
      min-width: 100%;
      min-height: 100px;
      resize: vertical;
    }
  }

  .twitter-typeahead {
    .validation-advice {
      position: absolute;
      top: 100%;
      left: 0;
    }
  }

  @include bp(max-width, $bp-small) {
    float: none;
    width: auto;
    margin-bottom: 30px;

    .opener-wrap {
      float: none;
      width: auto;
    }

    .opcheckout-comment {
      .text-form {
        padding-top: 0;
      }
    }

    .checkout-end {
      float: none;
      width: auto;

      .custom-checkbox {
        font-size: 14px;
      }
    }
  }
}

.comment-opener,
.coupon-opener {
  display: inline-block;
  font-weight: 500;
  color: $main-color;

  &:hover {
    color: #bebdbc;
  }

  label {
    cursor: pointer;
  }

  .icon-arrow {
    font-family: BaseIcons;
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    line-height: 1;
    font-style: normal;
    @include trs(transform .3s ease);

    &:before {
      content: "\e260";
    }
  }

  &.opened {
    .icon-arrow {
      transform: rotate(180deg);
    }
  }
}

.agreement-content {
  padding: 30px 40px;
  font-size: 14px;
  line-height: 20px;

  @include bp(max-width, $bp-small) {
    padding: 20px 20px;
  }
}

.checkout-actions-wrap {
  .checkout-tabs {
    margin-bottom: 30px;
    font-size: 0;

    li {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      border-bottom: 1px solid #dedcdf;
      box-sizing: border-box;

      &.active {
        a {
          color: $main-color;

          &:after {
            width: 100%;
          }
        }
      }
    }

    li + li {
      margin-left: -1px;
    }

    a {
      position: relative;
      display: block;
      text-align: center;
      padding: 9px;
      font-size: 14px;
      line-height: 20px;

      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 0;
        height: 3px;
        margin-top: -2px;
        background-color: $main-color;
        @include trs(width .3s ease);
      }

      .no-touch &:hover:after {
        width: 100%;
      }
    }

    @include bp(max-width, $bp-small) {
      a {
        line-height: 1.2em;
      }
    }

    > li {
      @include bp(max-width, $bp-smallest) {
        width: 100%;
        float: none;

        &:last-child {
          border-top-color: transparent;
          margin-top: -1px;
          margin-left: 0;
        }
      }
    }
  }

  .checkout-title {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .step {
    margin-bottom: 30px;
  }

  .credit-title {
    margin-top: 30px;
  }

  .field {
    margin-bottom: 20px;
    font-size: 0;
    @include cleaner;

    .description {
      float: right;
      width: 67.5%;
      margin-top: -12px;
      font-size: 14px;
    }

    > label {
      display: inline-block;
      vertical-align: middle;
      width: percentage(130/400);
      padding-right: 20px;
      font-size: 14px;
      line-height: 1.2em;
      box-sizing: border-box;
    }

    .input-box {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      width: percentage(270/400);
      font-size: 16px;
      @include cleaner;

      .chosen-container {
        min-width: 100%;
        max-width: 100%;

        .chosen-search input[type="text"] {
          min-height: 26px;
        }
      }

      .twitter-typeahead {
        display: block !important;
      }

      .tt-dropdown-menu {
        background: #fff;
        width: 100%;
        right: 0 !important;
        box-sizing: border-box;
        border: 1px solid #ddd;
        padding: 3px;
        font-size: 16px;
        max-height: 400px;
        overflow-x: hidden;
        overflow-y: scroll;

        .tt-suggestion {
          cursor: pointer;
          color: #333;
          @include trs(color .2s ease);

          &.tt-cursor {
            color: $main-color;
            background: #fff;
          }
        }
      }

      [class^="box"] {
        position: relative;
        float: left;
        padding: 0 5px;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        ::-webkit-input-placeholder {
          font-weight: $light;
          color: #ccc;
        }

        :-moz-placeholder {
          /* Firefox 18- */
          font-weight: $light;
          color: #ccc;
        }

        ::-moz-placeholder {
          /* Firefox 19+ */
          font-weight: $light;
          color: #ccc;
        }

        :-ms-input-placeholder {
          font-weight: $light;
          color: #ccc;
        }
      }

      .box2 {
        width: 50%;
      }

      .box3 {
        width: percentage(65/270);

        input {
          text-align: center;
        }
      }

      .box4 {
        width: percentage(55/270);

        input {
          text-align: center;
        }
      }

      .box2-3 {
        width: percentage(215/270);
      }

      .box2-4 {
        width: percentage(150/270);
      }

      @include bp(max-width, $bp-small) {
        [class^="box"] {
          float: none;
          width: auto;
          margin-bottom: 10px;
          padding: 0;

          input {
            text-align: left;
          }
        }
      }
    }

    .map-shower-wrap {
      text-align: right;

      .show-map {
        display: inline-block;
        padding: 10px 0 3px;
        font-size: 14px;
        line-height: $lh-small;

        .text {
          border-bottom: 1px dashed;
        }
      }

      @include bp(min-width, $bp-small+1) {
        .show-map {
          max-width: 67.5%;
        }
      }
    }

    input[type="text"],
    input[type="password"] {
      display: block;
      width: 100%;
      padding: 9px;
      color: #000;
      font-size: 16px;
      line-height: $lh-large;
    }

    .radio-wrap {
      @include cleaner;

      //      [type="radio"] {
      //        opacity: 0;
      //      }

      .radio-box {
        float: left;
        width: 33.33%;
        padding: 5px;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    .radio-label {
      cursor: pointer;
    }

    .radio-emu {
      display: inline-block;
      vertical-align: top;
      position: relative;
      width: 18px;
      height: 18px;
      margin: 3px 5px 0 0;
      border: 1px solid #7f7f7f;
      border-radius: 50%;

      &:before {
        content: "";
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 6px;
        height: 6px;
        margin-top: -3px;
        margin-left: -3px;
        background: #7f7f7f;
        border-radius: 50%;
      }

      &.active:before {
        display: block;
      }
    }

    @include bp(max-width, $bp-small) {
      > label {
        font-size: 14px;
      }

      .radio-label {
        text-align: center;
      }

      .radio-emu {
        display: block;
        margin: 0 auto;
      }

      .label-text {
        display: block;
      }
    }
  }

  .form-list {
    label {
      display: inline-block;
      vertical-align: middle;
      width: 32.5%;
      padding-right: 20px;
      font-size: 14px;
      line-height: 1.2em;
      box-sizing: border-box;
      font-weight: normal;
      float: none;
    }

    .input-box {
      //margin-left: 32.5%;
    }
  }

  .actions-set {
    text-align: center;
    width: 100%;

    &:after {
      display: none;
    }

    .buttons-set {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;

      @include bp(max-width, $bp-smallest){
        flex-direction: column-reverse;

        button {
          margin-bottom: 20px;
        }
      }

      &:after {
        display: none;
      }
    }

    .forgot-pswd-link {
      margin: 0;
    }

    .social-list {
      .soc-item {
        margin: 0 5px 10px;
      }
    }
  }

  .forgot-pswd-link {
    display: block;
    margin-bottom: 20px;

    .text {
      display: inline-block;
      line-height: $lh-smallest;
      border-bottom: 1px dashed;
    }
  }

  .btn-red {
    display: block;
    margin-bottom: 24px;
    width: 100%;

    &.no-display {
      display: none;
    }
  }

  .social-login {
    .soc-invent {
      display: block;
      margin-bottom: 10px;
    }

    .reg-link {
      .text {
        display: inline-block;
        line-height: $lh-smallest;
        border-bottom: 1px dashed;
      }
    }

    .social-list {
      a {
        color: #ccc;
        border-color: #ccc;

        .no-touch &:hover {
          color: #ddd;
          border-color: #ddd;
        }

        .wrapper &:active {
          color: #ccc;
          border-color: #ccc;
        }
      }
    }
  }

  select {
    min-width: 180px;
    width: 100%;
    height: 46px;
  }

  @include bp(min-width, $bp-small+1) {
    .next-all {
      display: block !important;
      height: auto !important;
    }
  }

  @include bp(max-width, $bp-small) {
    .actions-set {
      float: none;
      width: auto;
    }

    .checkout-title.opener-inited {
      display: inline-block;
      margin-bottom: 0;
      cursor: pointer;
      transition: padding .4s ease-out;

      &.active {
        padding-bottom: 20px;
      }

      .inner-text {
        .text {
          border-bottom: 1px dashed #ccc;
        }
      }

      .ico-down-arrow {
        display: inline-block;
      }
    }

    .next-all {
      .field {
        margin-bottom: 0;
        padding-bottom: 20px;
      }
    }
  }
}

.checkout-onepage-success {
  .obj {
    display: block;
    text-align: center;
    margin: 10px 0;
  }
  .item-options {
    margin: 10px 0 0;
    text-align: center;
    
    li {
      //width: 50%;
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      box-sizing: border-box;
    }

    .option-label {
      padding-right: 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: right;
    }

    .option-value {
      padding-left: 5px;
      font-weight: 500;
      text-align: left;
      display: inline;

      &:after {
        content: '';
        display: block;
        width: 100%;
      }
    }
  }
}

.checkout-main {
  .checkout-shopping-bag {
    float: right;
    width: 51%;
    padding: 60px 5%;
    box-sizing: border-box;
    border: 1px solid #d7d7d7;
    background: $light;

    @include bp(max-width, 1199px) {
      padding: 30px 20px;
    }

    .checkout-title {
      font-size: 1.125rem;
      margin-bottom: 30px;
      font-weight: 500;

      .edit-link {
        float: right;
        padding-top: 10px;
        font-size: 16px;
        line-height: $lh-smallest;
        border-bottom: 1px dashed;
      }
    }

    .checkout-item {
      margin-bottom: 40px;
      font-size: 14px;
      @include cleaner;

      .item-options {
        max-width: 380px;
        font-size: 0;

        li {
          display: inline-block;
          vertical-align: middle;
          font-size: 14px;
          float: left;
          box-sizing: border-box;
        }

        .option-label {
          padding-right: 5px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          clear: left;
        }

        .option-value {
          padding-left: 5px;
          font-weight: 500;
        }
      }

      .product-image {
        float: left;
        margin-right: 15px;
        transition: opacity .2s ease-out;

        @include bp(max-width, 540px) {
          float: none;
          display: block;
          text-align: center;
          margin-right: 0;
          margin-bottom: 20px;
        }

        .no-touch &:hover {
          opacity: .8;
        }

        img {
          border: 1px solid #e4e0e5;
          @include trs(border-color .3s ease);
        }

        &:hover {
          img {
            border-color: $main-color;
          }
        }
      }

      .product-name {
        display: block;
        margin-bottom: 20px;
        line-height: $lh-small;
        font-size: 1rem;
        font-weight: 500;
      }

      .checkout-item-details {
        float: left;
        width: calc(100% - 120px);
        @include cleaner;

        @include bp(max-width, 540px) {
          float: none;
          width: 100%;
        }
      }

      .obj {
        display: block;
        margin-bottom: 12px;
        color: #636363;
        line-height: $lh-small;

        > span {
          color: $main-color;
        }
      }

      .product-info {
        float: left;
        width: calc(100% - 190px);
        padding-right: 10px;
        box-sizing: border-box;

        @include bp(max-width, 540px) {
          width: 100%;
          float: none;
          padding-right: 0;
        }
      }

      .price-col {
        float: right;
        text-align: right;
        width: 190px;
      }

      .price {
        font-size: 1.3rem;
        font-weight: 500;
        color: $main-color;
        margin-right: 20px;

        @include bp(max-width, $bp-small) {
          margin-right: 10px;
        }

        .num {
          display: inline-block;
          margin-right: 3px;
          font-size: 18px;
          font-weight: 500;
        }

        .curr {
          font-weight: $regular;
        }
      }

      .btn-remove {
        font-size: 24px;
        color: #c4bec5;
        width: 20px;
        height: 20px;

        &:hover {
          color: $main-color;
        }
      }

      .product-counter {
        font-size: 18px;
        padding-top: 10px;
      }

      @include bp(max-width, $bp-small) {
        margin-bottom: 20px;

        .price {
          .num {
            font-size: 16px;
          }
        }

        .product-counter {
          font-size: 14px;
        }
      }
    }

    .totals-wrap {
      padding-top: 25px;
      text-align: right;
      font-size: 0;
      border-top: 1px solid #ccd;

      .totals-item {
        margin-bottom: 10px;
        color: #aaa4ac;

        .totals-label,
        .totals-price {
          font-size: 14px;
          line-height: $lh-small;
        }

        &:last-child {
          margin-bottom: 0;
          color: #47344c;
          font-weight: 500;

          .totals-label,
          .totals-price {
            font-size: 21px;
            vertical-align: baseline;
          }

          .price {
            .num {
              font-size: 36px;
            }
          }
        }
      }

      .totals-label,
      .totals-price {
        display: inline-block;
        vertical-align: middle;
        width: 50%;
        text-align: left;

        @include bp(max-width, $bp-small) {
          width: auto;
        }
      }

      .totals-price {
        text-align: right;
      }

      .price {
        .num {
          margin-right: 5px;
          font-size: 24px;
          font-weight: 500;
        }

        .curr {
          font-size: 16px;
          font-weight: $regular;
        }
      }

      @include bp(max-width, $bp-small) {
        .totals-item {
          .totals-label {
            padding: 0;
            font-size: 14px;
          }

          .totals-price {
            padding-left: 10px;
          }

          &:last-child {
            .price .num {
              font-size: 24px;
            }

            .totals-label {
              font-size: 18px;
            }
          }
        }

        .price {
          .num {
            font-size: 18px;
          }

          .curr {
            font-size: 14px;
          }
        }
      }
    }

    @include bp(max-width, $bp-small) {
      float: none;
      width: auto;
      padding: 3%;
      border: 0;
      background: #ebebeb;
    }
  }

  .action-mobile {
    display: none;

    button {
      width: 250px;
      height: 50px;
    }
  }

  @include bp(max-width, $bp-small) {
    .checkout-end {
      display: none;
    }

    .action-mobile {
      display: block;
      padding-top: 20px;

      @include bp(max-width, $bp-smallest) {
        button {
          width: 100%;
        }
      }
    }
  }

  @include bp(max-width, $bp-tiny) {
    .page-title {
      text-align: center;
    }
  }
}

#payment_form_liqpay li {
  font-size: 1rem;
}

//Spinner

.ui-spinner {
  border: none;
  border-radius: 0;
  > input {
    font-size: 1rem;
    width: 62px;
    height: 30px;
    border: none;
    background: darken(#fafafa, 10%);
    text-align: center;
    margin: 0;
    padding: 5px 30px 5px 0;
    color: $dark;
    box-sizing: border-box;
    box-shadow: none;
  }

  .ui-spinner-button {
    border: none;
    width: 30px;
    height: 14px;
    background: darken(#fafafa, 10%);
    border-left: 1px solid $white;
    cursor: pointer;

    &:hover {
      background: darken(#fafafa, 20%);
    }

    span {
      padding: 0;
      display: block;
      height: 100%;
      width: 100%;
      text-align: center;
      background: none;
      position: relative;
      margin-top: 0;
      top: 0;
    }

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      font-size: 12px;
      line-height: 1;
      color: $dark;
    }

    &.ui-spinner-up {
      border-bottom: 1px solid $white;
      &:after {
        content: '+';
      }
    }
    &.ui-spinner-down {
      &:after {
        content: '-';
      }
    }
  }
}


.checkout-billing {
  .form-list {
    .input-box {
      margin-left: 0;
    }

    .payment-description {
      margin-bottom: 20px;
    }

    .payment-method-table {
      width: 100%;
      border: 1px solid #D9DDE3;
      margin: 50px 0 20px;

      td {
        padding: 10px 15px;
        border: 1px solid #D9DDE3;
        vertical-align: middle;
      }

      .payment-method .title,
      .title {
        width: 100%;
        display: block;
        font-size: 12px;
        color: #333;
        margin-bottom: 5px;
        padding: 0;
      }

      .value {
        display: block;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;

        &.pay-per-month {
          /* padding-bottom: 10px;
          border-bottom: 1px solid #D9DDE3; */
        }
      }

      .payment-method {
        .input-box {
          width: 100%;
        }
      }

      @include bp(max-width, 640px){
        border: none;

        td {
          display: block;
          border-width: 0 0 1px;
          padding: 0;

          .title {
            padding: 20px 0 0;
          }
          .value {
            padding: 0 0 15px;
          }
        }

        .value {
          margin-bottom: 0;
        }
      }
    }
  }
}