// BREAKPOINTS
$bp-medium  : 1365px;
$bp-small   : 1023px;
$bp-desktop : 991px;
$bp-tiny    : 767px;
$bp-smallest: 479px;
$bp-micro   : 320px;

// FONT-WEIGHTS
$bold        : 700;
$medium      : 500;
$regular     : 400;
$light       : 300;

// LINEHEIGHTS
$lh-large       : 1.618;
$lh-medium      : 1.375;
$lh-small       : 1.2;
$lh-smallest    : 1;

//COLORS
$main-color:	#000;
$link:			#23c7e4;
$link-hover:	#00f6ff;
$dark:			#5b4e5e;
$darkest:		#3e3341;
$gray:			#918594;
$border-color:	#5c4c54;
$border-color-light: #d7d1d1;
$light:			#fafafa;
$white:			#ffffff;
$blue_light:	#a9f2ff;
$blue:			#00b8d8;