.catalogsearch-result-index,
.catalog-category-view {
  .main {
    padding-top: 0;
    padding-bottom: 100px;

    .col-main {
      .page-title ~ .messages {
        margin: 0 0 80px;

        & ~ .category-image,
        & ~ .category-banner {
          margin-top: -60px;
        }
      }
    }
  }

  .category-title {
    margin-bottom: 30px;
    width: calc(100% - 660px);

    @include bp(max-width, 1280px){
      width: 100%;
    }

    h1 {
      font-size: 28px;
      font-weight: 300;
      color: $main-color;
    }
  }

  @include bp(max-width, 960px){
    .inner-container {
      padding: 0 15px;
    }
  }

  .col-main {
    width: 100%;

    .category-image,
    .category-banner {
      margin-bottom: 80px;
    }
  }
}

.category-products {
  position: relative;

  .toolbar {
    position: absolute;
    right: 0;
    bottom: 100%;
    @include cleaner;

    select {
      width: 210px;
      
      @include bp(max-width, $bp-tiny){
        margin-top: -1px;
      }
    }

    @include bp(max-width, 1280px){
      position: relative;
      top: unset;
      bottom: unset;
      left: unset;
      right: unset;
      float: right;
    }

    .chosen-container {
      margin-right: 0;

      .chosen-single {
        border: none;
        font-size: 14px;
        color: #000000;
        padding: 0 20px 0 10px;
        height: 28px;

        div {
          width: 20px;
          border: none;
        }
      }

      .chosen-drop {
        width: auto;
        min-width: 100%;

        li {
          &.highlighted {
            font-weight: normal;
            color: #000;
          }
        }
      }

      .chosen-results {

        &::-webkit-scrollbar {
          width: 4px;

          &:horizontal {
            height: 4px;
          }
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #d8d8d8;
          border-radius: 4px;

          &:hover {
            background-color: #000;
          }
        }

        li {
          border: none;
          background: #fff;
          font-size: 12px;
          font-weight: normal;
          line-height: 22px;
          color: $main-color;
          padding: 14px 32px;
          position: relative;

          &:not(:last-child) {
            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 32px;
              right: 32px;
              height: 1px;
              background: #f3f3f2;
            }
          }

          &.result-selected {
            color: rgba(0, 0, 0, 0.4);

            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 4px;
              background: #a9f2ff;
            }
          }

          &.highlighted {
            background: #f3f3f2;
          }
        }
      }
    }

    .sort-by {
      position: relative;

      label {
        display: block;
        min-width: 210px;
        height: 28px;
        display: flex;
        align-items: center;
        padding: 0 20px 0 0;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        font-size: 12px;
        color: #7d7d7d;
        position: relative;

        @include bp(min-width, $bp-tiny+1){
          float: left;
        }
      }

      select {
        height: 28px;
        padding: 0 30px 0 8px;
      }


      .chosen-container {
        width: auto !important;
        position: absolute;
        padding: 18px 32px 0;
        top: -18px;
        left: -32px;

        &:after {
          @include icon;
          content: $arrow_down;
          font-size: 16px;
          position: absolute;
          width: 20px;
          height: 20px;
          top: 22px;
          right: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: rotate(0deg);
          transition: transform 0.3s;
        }

        .chosen-single {
          opacity: 0;
        }

        .chosen-drop {
          background: none;
          position: relative;
          height: 0;
          margin: 0 -32px;
          border: none;
        }

        &.chosen-with-drop {
          box-shadow: 0 0 30px 0 #cdcdcd;

          &:after {
            transform: rotate(-180deg);
          }

          .chosen-drop {
            height: auto;
          }
        }

        .chosen-results {
          padding-top: 10px;

          .active-results {
            
          }
        }
      }

      > button {
        float: right;
        width: 28px;
        height: 28px;
        background: #fafafa;
        border: none;
        margin-left: 32px;
        position: relative;
        cursor: pointer;

        .touch & {
          @include bp(max-width, $bp-tiny){
            margin-top: 28px;
          }
        }

        &:before {
          @include icon;
          content: $play;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $main-color;
        }

        &[class*="asc"] {
          &:before {
            transform: rotate(90deg);
          }
        }

        &[class*="desc"] {
          &:before {
            transform: rotate(-90deg);
          }
        }

        &:hover {
          background: #fff !important;
          box-shadow: 0 0 30px 0 #cdcdcd;
        }
      }
    }

    .limiter {
      margin-left: 20px;

      .chosen-container {
        min-width: 60px;
      }

      label {
        opacity: 0.5;
        margin: 0;
      }

      .per-page {
        display: none;
      }
    }

    .sorter {
      padding: 6px 0;
      float: left;
    }

    .filter-opener {
      box-sizing: border-box;
      padding: 0 50px 0 40px;
      margin: 0 0 0 35px;
      float: right;
      border-radius: 20px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      color: $main-color;
      height: 40px;
      border-radius: 20px;
      background: #c9f7ff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s;

      &:before {
        @include icon;
        content: $filter;
        font-size: 36px;
      }

      &:hover {
        background: $blue_light;
      }

      @include bp(max-width, $bp-tiny){
        position: fixed;
        top: 50%;
        left: 0;
        z-index: 9998;
        border-radius: 20px 20px 0 0;
        transform: rotate(90deg) translate(-50%, 110px);
      }
    }
  }
}

.category-products-grid {
  width: 100%;
  min-height: 330px;
  margin: 0 1px;
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 25%;
    margin: 0 -1px -1px 0;
    box-sizing: border-box;
    padding: 0;
    border: 1px solid #dedede;
    position: relative;
    overflow: hidden;

    .no-touch & {
      &:hover {
        overflow: visible;
      }
    }

    @include bp(max-width, 1180px){
      width: 33.33%;
    }

    @include bp(max-width, $bp-tiny){
      width: 50%;
    }
    @include bp(max-width, $bp-smallest){
      width: 100%;
    }

    .item-inner {
      padding: 0;
      background: #fff;
      min-height: 100%;
      width: 100%;

      .no-touch & {
        .hide-item-block {
          display: none;
        }

        &:hover {
          box-shadow: 0 0 30px 0 #cdcdcd;
          position: absolute;
          z-index: 2;

          .hide-item-block {
            display: block;
          }
        }

        @include bp(max-width, $bp-tiny){
          .hide-item-block {
            display: block;

            .add-to-links {
              display: block !important;
              opacity: 1 !important;
            }
          }

          &:hover {
            box-shadow: none;
            position: static;
          }
        }
      }
    }

    .info-block {
      padding: 0 32px 32px;
    }

    .product-image-wrapper {
      padding: 30px 0 0;
      text-align: center;
      position: relative;
    }

    .sticker-wrapper {
      &.top-left {
        top: -1px;
      }
    }

    .product-name {      
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      text-align: center;
      color: $main-color;
      margin: 15px auto 20px;
      max-width: 200px;
      height: 36px;
      overflow: hidden;

      &:hover {
        color: #333;
      }
    }

    .price-box {
      min-height: unset;
      margin: 0 0 -16px;
      width: 100%;
      height: 32px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 0;

      .minimal-price,
      .regular-price,
      .special-price,
      .old-price {
        margin: 0 5px;
        text-align: center;

        .price {
          font-size: 14px;
          line-height: 1;
          color: $main-color;
        }
      }

      .regular-price,
      .special-price {
        .price {
          font-weight: 500;
        }
      }

      .old-price {
        .price {
          opacity: 0.3;
        }
      }
    }

    .no-rating,
    .ratings {
      display: none;
    }

    .hide-item-block {
      margin-top: 25px;
      margin-bottom: -10px;

      .hide-item-block-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        flex-wrap: wrap;

        .add-to-links-wrap {
          margin: 0 auto 10px;

          .add-to-links {
            position: static;
            margin: 0 -8px;

            li {
              margin: 0 8px;

              button {
                margin: 0;
              }
            }
          }
        }

        .actions-block {
          margin: 0 auto 10px;
        }
      }
    }
  }
}

.filter-block {
  position: fixed !important;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  margin: 0;
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.3s;

  .filter-close {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 18px;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }

    &:before {
      @include icon;
      content: $close;
    }

    span {
      display: none;
    }
  }

  .current-filters {
    background: #f3f3f2;

    .block-subtitle {
      margin: 0;
      display: none !important;
    }

    .currently {
      padding: 0 40px;
      margin-top: -20px;

      .multiselect-child {
        display: inline-block;
        padding: 3px 32px 3px 16px;
        border-radius: 12px;
        border: none;
        background: #fff;
        font-size: 12px;
        line-height: 18px;
        color: #3a3a3a;
        margin-bottom: 8px;
        margin-right: 8px;
        position: relative;

        .btn-remove {
          margin: 0;
          position: absolute;
          top: 2px;
          right: 10px;
          
          &:before {
            @include icon;
            content: $close;
            font-size: 12px;
            font-weight: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }

        &:hover {
          box-shadow: 0 0 30px 0 #cdcdcd;
        }
      }
    }

    .actions {
      padding: 10px 40px 30px;
      margin: 0;

      a {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.29;
        color: #ff3d00;

        &:hover {
          color: #ff7600;
        }
      }
    }
  }

  #narrow-by-list {
    margin: 0;
    padding: 0;

    &.no-display {
      display: block !important;
    }

    dt {
      font-size: 14px;
      font-weight: normal;
      line-height: 18px;
      color: $main-color;
      padding: 30px 40px 20px;
      border-bottom: none;

      &:not(:first-child) {
        border-top: 1px solid #dedede;
      }

      &:after {
        display: none;
      }
    }

    dd {
      margin-bottom: 0;
      padding: 0 40px 8px;
      
      li {
        padding: 0;
        margin-bottom: 24px;
      }

      ol[data-price-item] {
        padding-bottom: 24px;

        li {
          margin-bottom: 0;
        }
      }
    }

    .layerednavigation-slider {
      .handle {
        width: 19px;
        height: 19px;
        background: #a9f2ff;
        border: none;
        border-radius: 50%;
        margin-top: -11px;
        margin-left: -10px;
      }

      &:before {
        height: 1px;
        background: rgba(0, 0, 0, 0.2);
      }

      .layerednavigation-slider-bar {
        top: -1px;
        height: 3px;
        background: #a9f2ff;
      }
    }

    .layerednavigation-slider-price {
      margin-top: 15px;
      opacity: 0.4;
      font-size: 14px;
      font-weight: normal;
      color: $main-color;
    }

    .price-range-btn-wrap {
      display: none;
    }

    .inpts-wrap {
      width: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .price-range-from,
      .price-range-to {
        width: 67px;
        height: 46px;
        
        input {
          display: block;
          width: 100%;
          height: 100%;
          background: none;
          border-radius: 4px;
          border: 2px solid #dbdbdb !important;
        }
      }

      .f-to {
        font-size: 14px;
        color: #000000;
      }
    }


    .configurable-swatch-list {
      li {
        float: left;
        margin: 0 -1px 0 0;
        padding: 0;

        a {
          margin: 0;
          border: 1px solid rgba(180, 180, 180, 0.3);
          width: 50px;
          height: 50px !important;
          padding: 0;
          margin: 0;

          .count {
            display: none;
          }

          .swatch-label {
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100% !important;
            font-size: 16px;
            color: #000000;
            background: #fff;
            margin: 0;
            padding: 0;
          }
        }

        &.selected,
        &:hover {
          > a {
            .swatch-label {
              background: rgba(180, 180, 180, 0.3);
            }
          }
        }
      }
    }

    .img_wr {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
      overflow: hidden;
      display: block;
      cursor: pointer;
    }

    .apply-filter {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 50px;
      border-radius: 3px;
      background: #d01f45;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1.1px;
      color: #ffffff;
      text-transform: uppercase;
      border: 2px solid #d01f45;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background: #fff;
        color: #d01f45;
      }

      display: none;
    }
  }

  .filter-block-inner {
    margin: 0;
    box-sizing: border-box;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    max-width: 370px;
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    transform: translateX(100%);
    transition: transform 0.3s;

    .block-content {
      padding: 0 0 10px;
    }

    //SCROLL SHANGE
    &::-webkit-scrollbar {
      width: 4px;

      &:horizontal {
        height: 4px;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: #f2f2f2;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $main-color;
      border-radius: 4px;

      &:hover {
        background-color: $main-color;
      }
    }
  }

  &.open {
    visibility: visible;
    opacity: 1;

    .filter-block-inner {
      transform: translateX(0);
    }
  }

  div.block-layered-nav div.layerednavigation-slider div.handle {
    &:after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      right: -9px;
      top: -3px;
      border-top: 9px solid transparent;
      border-left: 6px solid #ff9631;
      border-bottom: 9px solid transparent;
    }

    &:last-child {
      &:after {
        right: auto;
        left: -9px;
        border-left: none;
        border-right: 6px solid #ff9631;
      }
    }
  }

  .block-layered-nav .block-content > dl > dt {
    overflow: hidden;
    font-size: 1rem;

    span {
      position: relative;

      &:after {
        position: absolute;
        content: '';
        height: 1px;
        width: 1000%;
        top: 50%;
        left: 100%;
        background: $border-color-light;
        margin-left: 14px;
      }
    }
  }

  .block-title {
    display: block;
    padding: 40px;
    font-size: 28px;
    font-weight: 300;
    color: $main-color;
    background: #f3f3f2;

    &:after {
      visibility: hidden;
    }
  }

  .block-subtitle--filter {
    display: none;
  }
}

.toolbar-bottom {
  position: relative;
  padding: 0;
  margin: 0;
  margin-top: 55px;

  .toolbar {
    position: relative;
    top: 0;
    left: 0;
    right: unset;
    bottom: unset;
  }

  .toolbar {
    margin: 0;

    .filter-opener {
      display: none;
    }
  }
}

.pager {
  padding: 0;

  .pages {
    > strong {
      display: none;
    }

    li {
      border: none;
      width: 32px;
      height: 32px;
      margin: 0 9px;
      box-sizing: border-box;
      border: 1px solid #fff;
      border-radius: 50%;
      font-size: 14px;
      font-weight: 300;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $main-color !important;
      @include trs(border-color .3s ease);

      a {
        width: 32px;
        height: 32px;
        font-size: 14px;
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $main-color;
      }

      &:hover {
        border-color: rgba(204, 204, 204, 0.6);
        background: none;
        color: $main-color;

        a {
          background: none;
          color: $main-color;
        }
      }
      &.current {
        border-color: rgba(0, 0, 0, 0.05);
        background: rgba(0, 0, 0, 0.05);
        color: $main-color;

        a {
          background: none;
          color: $main-color;
        }
      }

      &.next {
        //float: right;
        margin: 0;
        margin-left: 65px;

        @include bp(max-width, $bp-smallest){
          margin-left: 9px;
        }
      }

      &.previous {
        //float: left;
        margin: 0;
        margin-right: 65px;

        @include bp(max-width, $bp-smallest){
          margin-right: 9px;
        }
      }

      &.next,
      &.previous {
        width: auto;
        background: none;

        &.disable {
          pointer-events: none;
          opacity: 0.3;
        }

        a {
          font-size: 0;

          &:hover {
            background: none;
          }
        }

        span {
          display: none;
        }

        i {
          width: 32px;
          height: 32px;
          font-size: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: rotate(90deg);

          &:before {
            content: $arrow_right;
          }
        }
      }
    }
  }
}

.note-msg.empty-catalog {
  display: flex;
  align-items: center;

  &:before {
    background-size: contain;

    @include bp(max-width, 560px) {
      width: 80px;
      height: 80px;
      min-width: 80px;
    }
  }
}

.img-desc-mode {
  @media (max-width: 1366px) {
    display: none;
  }
}

.img-pad-mode {
  display: none;
  @media (min-width: 770px) {
    @media (max-width: 1365px) {
      display: block;
    }
  }
}

.img-mob-mode {
  display: none;
  @media (max-width: 769px){
    display: block;
  }
}