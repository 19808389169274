.block-layered-nav {
  .multiselect-child {
	display: block;
  }

  .currently {
	&+.actions {
	  a {
		font-size: 1rem;
		color: $main-color;
		font-weight: bold;

		&:hover {
		  color: $dark;
		}
	  }
	}
  }

  .btn-remove-inline {
	margin-left: 0;
	margin-right: 4px;
  }

  dl dd ol > li > a {
	padding: 0;

	.count {
		color: $main-color;
		opacity: 0.4;
	}
  }

  #narrow-by-list {
	.configurable-swatch-list {
	  li {
		float: none;
		width: auto;
		margin: 0;
	  }
	}
  }

  [class^='brander_layerednavigation-attr'] {
	padding-left: 35px;
	position: relative;
  }

  .brander_layerednavigation-attr {
	padding-left: 35px;
  }

  [class^='brander_layerednavigation-attr'],
  .brander_layerednavigation-attr {
  	position: relative;
	
	font-size: 14px;
	font-weight: 300;
	line-height: 20px;
	color: $main-color;

  	&:before {
  		@include icon;
  		content: $check;
  		position: absolute;
  		top: 0;
  		left: 0;
  		width: 20px;
  		height: 20px;
  		border: 1px solid #dedede;
  		box-sizing: border-box;
  		color: transparent;
  		font-size: 20px;
  		display: flex;
  		align-items: center;
  		justify-content: center;
  	}
  }

  .brander_layerednavigation-attr-selected,
  .brander_layerednavigation-attr.checked,
  .brander_layerednavigation-attr:hover {
  	cursor: pointer;
  	
  	&:before {
  		border-color: #000;
  		color: #000;
  		box-shadow: inset 0 0 0 1px #000;
  	}
  }

  .layerednavigation-cat {
  	a {
  		&:hover {
  			color: rgba(0, 0, 0, 0.4);
  		}
  	}
  }

  .inpts-wrap {
	margin-bottom: 8px;
	font-size: 0;
	white-space: nowrap;

	.price-range-from,
	.f-to,
	.price-range-to {
	  display: inline-block;
	  font-size: 14px;
	}

	.price-range-to,
	.price-range-from {
	  width: 45%;
	}

	.f-to {
	  width: 10%;
	  text-align: center;
	}

	input {
	  width: 100%;
	  text-align: center;
	}
  }

  .price-range-btn-wrap {
	.layerednavigation-price-find {
	  width: 100%;
	}
  }

  &.layerednavigation-filters-left {
	.configurable-swatch-list {
	  > li {
		display: inline-block;
	  }

	  img {
		position: static;
		width: 20px;
		height: 20px;
	  }
	}
  }
}

.block-layered-nav .block-content > dl > dd {
	display: block;
}