#nav,
#nav-left {
  .section-title {
    font-size: 1.4em;
    margin-top: 10px;
  }

  .nav-panel-inner {
    padding: 0;
  }

  .nav-panel--dropdown {
    box-sizing: border-box;
    box-shadow: 0 15px 30px 0 #cdcdcd;

    &:before {
      content: '';
      position: absolute;
      top: -18px;
      left: 0;
      width: 100%;
      height: 18px;
      background: #fff;
      border-bottom: 1px solid rgba(0,0,0,0.1);
    }

    .nav-panel--dropdown {
      &:before {
        display: none;
      }
    }
  }

  &.nav-mobile {
    #lang-switcher-wrapper-regular {
      clear: both;
      border-top: 1px solid #e6e6e6;
      display: block;
      background: #fff;

      .lang-switcher {
        position: static;

        .dropdown-heading,
        &:before {
          display: none;
        }

        .dropdown-content {
          display: block !important;
          position: static;
          padding: 0;
          box-shadow: none;

          li:not(.current) {
            padding: 0;
            border: none;

            a {
              padding: 15px 10px;
              font-size: 16px;
              line-height: 1.2;
              font-family: 'GothamPro';
            }
          }
        }
      }
    }
    .nav-panel--dropdown {
      box-shadow: none;
      padding: 0;

      > .nav-item {
        a {
          >span:not(.caret) {
            border-bottom: none !important;
          }
        }
      }
    }
  }

  a:not(.mini-cart-heading) {
    .caret {
      border: none;
      position: absolute;
      top: 50%;
      margin: -8px 0 0 0;
      right: 5px;
    }
  }

  .nav-item {
    .nav-panel--dropdown {
      .nav-item.mega {
        .nav-submenu {
          .nav-item--only-subcategories {
            li {
              a {
                padding: 4px 20px 5px 32px !important;
              }

              &:after {
                left: 15px;
              }
            }
          }
        }
      }
    }
  }

  .nav-item--only-subcategories {
    &.simple {
      .nav-submenu {
        li {
          a {
            padding: 4px 20px 5px 32px !important;

            &:after {
              left: 15px;
            }
          }
        }
      }
    }
  }

  .itemslider-wrapper .owl-controls .owl-buttons {
    top: -38px;
  }

  [id^="nav-holder"] {
    .caret {
      display: none;
    }
  }
}

.header-regular {
  #nav,
  #nav-left {
    > .nav-item {
      > .nav-panel--dropdown {
        padding: 16px 0;
        width: 284px;


        > .nav-item {
          a {
            display: block;
            padding: 0 32px;
            line-height: 48px;

            > span:not(.caret) {
              display: block;
              border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            }

            .caret {
              width: 20px;
              height: 20px;
              margin-top: -10px;

              &:before {
                @include icon;
                content: $arrow_right;
                font-size: 20px;
              }
            }
          }

          &:last-child {
            a {
              > span {
                border-bottom: none;
              }
            }
          }
        }

        .nav-item {
          &:hover,
          & a:hover {
            background: rgba(0, 0, 0, 0.05);
            color: $main-color;
          }
        }
      }
    }
  }
}

#header-nav {
  @include bp(max-width, 959px) {
    .inner-container {
      padding: 0;
    }
  }
}

.header-regular:not(.sticky-header) {
  #nav:not(.nav-mobile) {
    display: flex;

    //display: table;
    //width: 100%;

    &.centered {
      justify-content: space-between;
    }

    > li {
      //display: table-cell;
      //text-align: center;

      //&:nth-child(2) {
      //text-align: left;
      //}
      //
      //&:last-child {
      //text-align: right;
      //}

      > a {
        .caret {
          display: none;
        }
      }
    }
  }
}

#sticky-nav,
#sticky-catalog-menu {
  display: none;
  visibility: hidden;
}

.sticky-header {
  .dropdown .dropdown-heading.cover > span {
    //padding: 0 16px;
  }

  #sticky-catalog-menu {
    display: block;
    float: left;
    visibility: visible;

    > a {
      &:after {
        display: none;
      }
    }
  }

  .nav-item--home {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 120px;

    img {
      vertical-align: middle;
    }
  }
}

#sticky-catalog-menu {
  .sticky-main-nav {
    > li {
      display: block;

      &:hover {
        > a {
          //color: $white;
        }
      }

      > .nav-submenu {
        left: 100% !important;
        top: 0 !important;
        display: none;
      }
    }
  }
}

.nav-regular {
  #sticky-catalog-menu {
    li.level0 {
      position: relative !important;
    }
  }

  li.level0 {
    position: static !important;
  }
  .classic {
    > .nav-panel--dropdown {
      padding: 0;
    }
  }
  .nav-panel {
    li.mega {
      &:hover {
        > .nav-panel--dropdown {
          left: 100%;
        }
      }
    }
  }

  .nav-submenu--mega > li.classic:hover > .nav-panel--dropdown {
    left: 100%;
  }
}

.the-slideshow-wrapper {
  @include cleaner;

  .the-slideshow {
    @include bp(max-width, 959px) {
      width: 100%;
    }
  }

  //&.fixed-nav {
  //@include bp(min-width, 960px) {
  //display: table;
  //width: 100%;
  //table-layout: fixed;

  //> div {
  //display: table-cell;
  //vertical-align: top;
  //float: none;
  //}

  //.vert-nav {
  //position: relative;

  //.nav-regular {
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  right: 0;
  //  min-height: 100%;
  //}

  //& + .the-slideshow {
  //  float: none !important;
  //}
  //}
  //}
  //}
}

#nav-left {
  > li {
    position: relative;

    > .nav-submenu {
      left: 100% !important;
      top: 0 !important;
    }

    .nav-submenu {
      padding: 12px 0;
      box-sizing: border-box;

      .caret {
        border: none;
      }
    }
  }

  .nav-item.first > a:before {
    display: none;
  }
}

#nav {
  > .nav-item {
    > .left-drop {
      /* left: auto !important;
      right: 0; */
    }

    .nav-submenu.left-drop {
      .nav-item--parent {
        > a {
          padding: 15px 12px 15px 25px;

          .caret {
            right: auto;
            left: 5px;
            margin-top: 1px;
            transform: rotate(90deg);
          }
        }
      }

      > li:hover {
        > .nav-panel--dropdown {
          left: auto;
          right: 100% !important;
        }
      }
    }
  }
}