.the-slideshow-wrapper-outer {
	.owl-controls {
		.owl-buttons {
			position: static;
			width: 100%;
			height: auto;

			div {
				width: 46px;
				height: 46px;
				margin-top: -23px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: rgba(255, 255, 255, 0.5) !important;
				border-radius: 0;
				border: none;
				top: 50%;

				&.owl-prev {
					left: 0;
				}

				&.owl-next {
					right: 0;
				}

				&:before {
					display: block;
					font-size: 40px;
					color: #231f20;
				}

				&:not(.disabled):hover {
					background-color: rgba(255, 255, 255, 1) !important;
				}
			}
		}

		.owl-pagination {
			position: absolute;
			bottom: 30px;

			@include bp(max-width, $bp-tiny){
				justify-content: flex-end;
				padding-right: 30px;
				box-sizing: border-box;
			}
		}
	}

	.info-block {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		position: absolute;
		top: 0;
		left: 0;
		padding: 12.5575% 9.6485% 6.4325%;
		display: flex;
		justify-content: space-between;
		flex-direction: column;

		.slideshow_description {
			font-size: 44px;
			font-weight: 300;
			line-height: 1.09;
			color: #4e4153;
		}
		.watch_more {
			width: 200px;
			height: 50px;
			border-radius: 25px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			line-height: 1.57;
			color: #4e4153;
			background: #fff;
			box-shadow: 0 0 50px 0 rgba(202, 202, 202, 0.3);
			transition: box-shadow 0.3s;

			&:hover {
				box-shadow: 0 0 50px 0 rgba(202, 202, 202, 1);
			}
		}

		@include bp(max-width, 960px){
			padding: 10% 5% 5%;

			.slideshow_description {
				font-size: 36px;
			}
		}

		@include bp(max-width, $bp-tiny){
			padding: 20px;

			.slideshow_description {
				font-size: 24px;
			}

			.watch_more {
				width: 180px;
				height: 40px;
				border-radius: 20px;
			}
		}
	}
}

.homepage-hotcategories {
  margin-bottom: 120px;
  padding-top: 20px;
  padding-bottom: 80px;
  counter-reset: hotcategories_counter;

  @include bp(max-width, 640px){
  	margin-bottom: 70px;
  	padding-top: 0;
  	padding-bottom: 0;
  }

  .page-banners {
  	display: flex;
  	flex-wrap: wrap;
  	@include cleaner;

  	$small_indent: 13px;

  	.hotcategory {
  		width: 50%;
  		margin: 0;

  		.hotcategory-inner {
  			display: flex;
  			justify-content: center;
  			align-items: center;
  			box-sizing: border-box;
  			width: 100%;

  			a {
 				display: block;
 				position: relative;

 				&:before {
 					content: '';
 					display: block;
 					position: absolute;
 					box-sizing: border-box;
 					border: 1px solid rgba(0, 0, 0, 0.15);
 				}

				img {
					display: block;
					position: relative;
					z-index: 1;
				}

  				.hotcategories-info {
  					position: absolute;
  					z-index: 2;
  					width: 260px;
  					min-width: 200px;
  					box-sizing: border-box;
  					padding: 40px 50px;
  					background: #fff;
  					box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);

  					&:before {
  						counter-increment: hotcategories_counter;
  						content: '0'counter(hotcategories_counter);
  						display: inline-block;
  						font-size: 18px;
  						font-weight: 300;
  						line-height: 1;
  						letter-spacing: 0.5px;
  						color: rgba(0, 0, 0, 0.3);
  						padding: 10px 0;
  						border-bottom: 1px solid;
  					}

  					.hotcategories-title {
  						display: block;
  						padding: 40px 0 20px;
  						font-size: 28px;
						font-weight: 300;
						color: $main-color;
  					}

  					.link {
  						display: inline-block;

  						&:after {
  							@include icon;
  							content: $arrow_link;
  							font-size: 16px;
  							position: relative;
  							top: 2px;
  							left: 5px;
  						}
  					}
  				}
  			}
  		}

  		&:nth-child(1){
  			.hotcategory-inner {
  				justify-content: flex-end;
  				padding-right: 3.065%;
  			}

  			a {
  				margin-top: 6.1635%;

  				&:before {
  					top: -$small_indent;
  					left: calc(#{$small_indent} * 3);
  					width: calc(100% - (#{$small_indent} * 6));
  					height: calc(100% + (#{$small_indent} * 2));
  				}

  				.hotcategories-info {
  					width: 54.4325%;
  					left: -($small_indent * 4);
  					bottom: ($small_indent * 3.3);
  				}
  			}
  		}

  		&:nth-child(2){
  			.hotcategory-inner {
  				justify-content: flex-start;
  				padding-left: 19.9%;
  			}

  			a {
  				margin-top: 0;

  				&:before {
  					top: calc(#{$small_indent} * 2);
  					left: -$small_indent;
  					width: calc(100% + (#{$small_indent} * 2));
  					height: calc(100% - (#{$small_indent} * 4));
  				}

  				.hotcategories-info {
  					width: 69.355%;
  					right: -($small_indent * 5.6);
  					bottom: ($small_indent * 3.3);
  				}
  			}
  		}

  		&:nth-child(3),
  		&:nth-child(4) {
  			margin-top: calc(-#{$small_indent} * 2.4615);
  		}


  		&:nth-child(3) {
  			.hotcategory-inner {
  				justify-content: flex-start;
  				padding-left: 14.3951%;
  			}

 			a {
 				margin: 0 auto;

 				&:before {
 					top: calc(#{$small_indent} * 2);
 					left: -$small_indent;
 					width: calc(100% + (#{$small_indent} * 2));
 					height: calc(100% - (#{$small_indent} * 4));
 				}

 				.hotcategories-info {
 					width: 60.6426%;
 					right: ($small_indent * 3.6);
 					bottom: -($small_indent * 6);
 				}
 			}
  		}

  		&:nth-child(4) {
  			.hotcategory-inner {
  				justify-content: flex-start;
  				padding-left: 9.64778%;
  			}

 			a {
 				margin-top: 23.73%;

 				&:before {
 					top: -$small_indent;
 					left: calc(#{$small_indent} * 2.9);
 					width: calc(100% - (#{$small_indent} * 5.8));
 					height: calc(100% + (#{$small_indent} * 2));
 				}

 				.hotcategories-info {
 					width: 74.8325%;
 					right: calc(-#{$small_indent} * 5);
 					bottom: calc(#{$small_indent} * 3.69);
 				}
 			}
  		}



  		@include bp(max-width, 1200px){
	 		&:nth-child(1) {
				a {
					.hotcategories-info {
						left: calc(-#{$small_indent} * 2);
					}
				}
	 		}

	 		&:nth-child(2) {
				a {
					.hotcategories-info {
						right: calc(-#{$small_indent} * 2);
					}
				}
	 		}

	 		&:nth-child(4) {
				a {
					.hotcategories-info {
						right: calc(-#{$small_indent} * 2);
						bottom: calc(#{$small_indent} * 1.5);
					}
				}
	 		}
  		}

  		@include bp(max-width, 1080px){
	  		.hotcategory-inner {
	  			width: 90%;

	  			a {
	  				.hotcategories-info {
	  					padding: 20px 30px 30px;

	  					&:before {
	  						font-size: 16px;
	  					}

	  					.hotcategories-title {
	  						padding: 30px 0 20px;
	  						font-size: 20px;
	  					}

	  					.link {
	  						display: inline-block;

	  						&:after {
	  							@include icon;
	  							content: $arrow_link;
	  							font-size: 16px;
	  							position: relative;
	  							top: 2px;
	  							left: 5px;
	  						}
	  					}
	  				}
	  			}
	  		}
	  		&:nth-child(1),
	  		&:nth-child(3) {
	  			.hotcategory-inner {
	  				float: right;
	  			}
	  		}

	  		&:nth-child(2),
	  		&:nth-child(4) {
	  			.hotcategory-inner {
	  				float: left;
	  			}
	  		}
  		}

  		@include bp(max-width, 900px){
  			&:nth-child(3),
  			&:nth-child(4) {
  				margin-top: 0;
  			}
  		}

  		@include bp(max-width, 640px){
  			width: 100%;
  			margin-bottom: 40px;

	  		.hotcategory-inner {
	  			width: 100%;
	  			position: relative;

	  			a {
	 				&:before {
	 					display: none;
	 				}
	  			}
	  		}

	  		&:nth-child(1),
	  		&:nth-child(2),
	  		&:nth-child(3),
	  		&:nth-child(4) {
	  			.hotcategory-inner {
	  				padding: 0;
	  				float: none;

	  				.hotcategories-info {
	  					background: rgba(255, 255, 255, 0.9);
	  				}

	  				a {
	  					margin: 0;
	  					padding: 0;
	  					position: static;
	  				}
	  			}
	  		}

	  		&:nth-child(1),
	  		&:nth-child(3) {
	  			.hotcategory-inner {
	  				justify-content: flex-start;

	  				.hotcategories-info {
	  					bottom: 10%;
	  					right: 0;
	  					left: auto;
	  					width: 280px;
	  				}
	  			}
	  		}

	  		&:nth-child(2),
	  		&:nth-child(4) {
	  			.hotcategory-inner {
	  				justify-content: flex-end;

	  				.hotcategories-info {
	  					bottom: 10%;
	  					right: auto;
	  					left: 0;
	  					width: 280px;
	  				}
	  			}
	  		}
  		}
  	}
  }
}

.not-full-width {
  .homepage-unitopblog,
  .homepage-productslider,
  .homepage-banners-container {
	&.container {
	  max-width: 100%;
	  width: 100%;
	}
  }
}


.homepage-pages-container {
	margin-bottom: 150px;

	@include bp(max-width, 640px){
		margin-bottom: 80px;
	}

	$homepage_pages_indent: 13px;

	.homepage-pages {
		margin: 0 -34px;
		@include cleaner;

		.homepage-pages-item {
			width: calc(33.33% - 68px);
			margin: 0 34px;
			float: left;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding-left: 3.4935%;
			box-sizing: border-box;
			position: relative;

			a {
				display: block;
			}

			.homepage-pages-item-inner {
				position: relative;

				img {
					position: relative;
					z-index: 1;
				}

				&:before {
					content: '';
					display: block;
					position: absolute;
					border: 1px solid rgba(0, 0, 0, 0.15);
					box-sizing: border-box;
					top: -$homepage_pages_indent;
					left: $homepage_pages_indent;
					width: calc(100% - (#{$homepage_pages_indent} * 2));
					height: calc(100% + (#{$homepage_pages_indent} * 2));
				}
			}

			.homepage-pages-item_info {
				position: absolute;
				z-index: 2;
				left: 0;
				bottom: calc(#{$homepage_pages_indent} * 2.5);
				font-size: 24px;
				font-weight: 300;
				color: $main-color;
				box-sizing: border-box;
				padding: 50px 50px 45px;
				background: #fff;
				cursor: pointer;
				box-shadow: 0 0 50px 0 rgba(209, 209, 209, 0.3), 0 0 30px 0 rgba(0, 0, 0, 0.15);

				.dots {
					margin-top: 25px;
					@include cleaner;

					span {
						width: 7px;
						height: 7px;
						border: 2px solid #a9f2ff;
						box-sizing: border-box;
						border-radius: 50%;
						float: left;
						margin-right: 10px;
					}
				}
			}
		}

		@include bp(max-width, 1024px){
			margin: 0 -15px;

			.homepage-pages-item {
				width: calc(33.33% - 30px);
				margin: 0 15px;
				padding-left: 0;

				.homepage-pages-item_info {
					padding: 30px;
				}
			}
		}

		@include bp(max-width, 640px){
			margin: 0;

			.homepage-pages-item {
				width: 100%;
				margin: 0 0 30px;
				padding-left: 0;

				.homepage-pages-item-inner {
					&:before {
						display: none;
					}
				}

				.homepage-pages-item_info {
					background: rgba(255, 255, 255, 0.9);
					padding: 30px;
					min-width: 280px;
				}
			}
		}
	}
}


.info-block-wrap {
	position: relative;
	padding: 0 3.4465% 3.0635% 4.9775%;
	display: flex;
	justify-content: flex-end;

	$info_block_indent: 13px;

	&:before,
	&:after {
		content: '';
		position: absolute;
		width: 230px;
		height: 1px;
		background: rgba(0, 0, 0, 0.2);
	}

	&:before {
		top: 17.2425%;
		left: 72px;
		left: 5.5135%;
		transform: rotate(-45deg) translate(-50%);
	}
	&:after {
		bottom: 0;
		right: 48px;
		transform: rotate(-45deg) translate(50%);
	}

	.info-block {
		max-width: 85%;
	}

	.info-block_image {
		position: relative;
		z-index: 2;

		img {
			position: relative;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			border: 1px solid rgba(0, 0, 0, 0.15);
			box-sizing: border-box;
			bottom: -$info_block_indent;
			left: calc(#{$info_block_indent} * 2);
			width: calc(100% - (#{$info_block_indent} * 4));
			height: calc((100% + (#{$info_block_indent} * 2)) - 10.895%);
		}
	}

	.info-block_info {
		position: absolute;
		z-index: 2;
		left: 4.9775%;
		top: 50%;
		margin-top: 0.8425%;
		width: 390px;
		box-sizing: border-box;
		padding: 60px;
		background: #fff;
		box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
		transform: translateY(-50%);

		.title {
			font-size: 46px;
			line-height: 1;
			font-weight: 300;
			color: $main-color;
			display: block;
			padding: 10px 0 35px;
			position: relative;

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 30px;
				height: 2px;
				background: rgba(0, 0, 0, 0.1);
			}
		}
		.description {
			display: block;
			font-size: 28px;
			font-weight: 300;
			line-height: 1.21;
			color: $main-color;
			padding: 30px 0 25px;
		}

		.link {
			display: inline-block;

			&:after {
				@include icon;
				content: $arrow_link;
				font-size: 16px;
				position: relative;
				top: 2px;
				left: 5px;
			}
		}
	}

	@include bp(max-width, 960px){
		.info-block_info {
			padding: 40px;
			background: rgba(255, 255, 255, 0.9);

			.title {
				font-size: 36px;
				padding: 10px 0 25px;
			}
			.description {
				font-size: 20px;
				padding: 20px 0 15px;
			}
		}
	}

	@include bp(max-width, 640px){
		padding: 0;
		margin-bottom: 70px;
		display: block;

		&:before,
		&:after {
			display: none;
		}

		.info-block_image {
			&:before {
				display: none;
			}
		}

		.info-block_info {
			position: static;
			width: 100%;
			transform: none;
			margin: 0;
		}
	}
}


.homepage-unitopblog {
  padding-top: 50px;
  padding-bottom: 50px;
  background-size: cover;

  @include bp(max-width, $bp-tiny) {
	padding-top: 20px;
	padding-bottom: 20px;
  }
}

.main-bottom-container {
	.category-description {
		padding: 0 20px;
	}

	.homepage-unitopblog {
		margin: 0;
	}
}

.preface {
  margin-bottom: 95px;

  @include bp(max-width, 1280px){
  	margin: 0 -15px;
  }
}

.text-block-wrapper {
	margin-bottom: 100px;
}