
.checkout-onepage-success {
	.main-container {
		.main {
			padding-top: 50px;
		}
	}

	strong {
		font-weight: 500;
	}

  .page-title {
	font-size: 32px;
	font-weight: 300;
	padding: 0 25px;

	@include bp(max-width, $bp-tiny) {
	  margin-bottom: 0;
	  padding: 20px 0;
	  font-size: 24px;
	}
  }

  .checkout-shopping-bag {
	float: none;
	width: auto;
	padding: 0;
	border: 0;

	.bag-title {
		font-size: 32px;
		font-weight: 300;
		padding: 0 25px;
		margin: 0 0 30px;
		text-align: center;
	}

	@include bp(max-width, $bp-small) {
	  margin: 0;
	  background: transparent;

	  .bag-title {
		font-size: 21px;
	  }
	}

	.products-list {
		display: flex;
		flex-wrap: wrap;

		.order-product-item {
			text-align: center;
			width: 31.33%;
			margin: 0 1% 15px;
			box-sizing: border-box;
			border: 1px solid #ccc;
			padding: 10px 5px;

			@include bp(max-width, 1024px){
				width: 48%;
			}

			@include bp(max-width, $bp-tiny){
				width: 98%;
			}
		}
	}

	.totals-wrap {
		.totals-item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;

			&.last-total {
				font-weight: 500;
				font-size: 1.3em;
			}
		}
	}
  }
}

.success-top {
  margin-bottom: 50px;
  text-align: center;

  .notes-wrap {
	margin: 0 auto 35px;
	line-height: 1.2;
  }

  .note {
	font-size: 24px;

	strong {
	  margin-left: 5px;
	}
  }

  .order-num-wrap {
	font-size: 16px;
	font-weight: 500;
  }

  .order-num {
	font-size: 48px;
	line-height: $lh-smallest;
  }

  @include bp(max-width, $bp-small) {
	padding: 0 0 40px;

	.notes-wrap {
	  max-width: 290px;
	}

	.note {
	  font-size: 18px;
	}

	.order-num {
	  font-size: 36px;
	}
  }
}

.success-bottom {
  max-width: 460px;
  padding-bottom: 120px;
  margin: 0 auto;

  .comment-title {
	display: block;
	font-size: 1rem;
	font-weight: 500;
  }

  .products-list {
	margin: 0 -10px 20px;
	@include cleaner;
  }

  .item-inner {
	padding: 10px;
  }

  .product-image {
	text-align: center;
	margin-bottom: 10px;
	max-width: 100px;
  }

  .order-product-item {
	float: left;
	width: 31.33333%;
	padding: 10px;
	margin: 1%;
	box-sizing: border-box;
	border: 1px solid #ccd;

	@include bp(max-width, 640px) {
	  width: 50%;
	}

	@include bp(max-width, $bp-smallest) {
	  width: 100%;
	}

	.product-name {
	  font-size: 1rem;
	  font-weight: 500;
	  text-align: center;
	  display: block;
	}

	.obj {
	  display: block;
	  margin-bottom: 10px;
	}

	.price-col {
	  font-weight: 500;
	}
  }

  .totals-wrap {
	line-height: 1.2;
	font-weight: 500;
	padding-top: 20px;
	margin: 0 -1%;
	border-top: 1px solid #ccd;

	.totals-item {
	  font-size: 1.1rem;
	  margin-bottom: 14px;
	  @include cleaner;

	  &:last-child {
		font-size: 2rem;
		margin-bottom: 0;
	  }
	}

	.totals-label {
	  float: left;
	  margin-right: 10px;
	}

	.totals-price {
	  float: right;
	}
  }
}

.about-order {
  margin-bottom: 60px;
  font-size: 16px;

  .title-wrap {
	margin-bottom: 20px;
  }

  .title {
	display: inline-block;
	margin-right: 30px;
	font-size: 32px;
  }

  .print-link {
	display: none;
	line-height: $lh-smallest;

	.text {
	  display: inline-block;
	  border-bottom: 1px dashed;
	}
  }

  .no-touch & {
	.print-link {
	  display: inline-block;
	}
  }

  .about-line {
	margin-bottom: 10px;

	.about-label {
	  display: inline-block;
	  vertical-align: middle;
	  min-width: 170px;
	  padding-right: 20px;
	}

	.about-val {
	  display: inline-block;
	  vertical-align: middle;
	}
  }

  @include bp(max-width, $bp-small) {
	font-size: 14px;

	.title {
	  margin-right: 20px;
	  font-size: 21px;
	}

	.print-link {
	  display: none;
	}

	.about-line {
	  font-size: 0;

	  .about-label {
		min-width: 0;
		width: 40%;
		font-size: 14px;
	  }

	  .about-val {
		width: 60%;
		font-size: 14px;
	  }
	}
  }
}