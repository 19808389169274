.footer-container {
	background: #fff linear-gradient(0deg, #fff, #f6f6f6);

	.block-footer-contacts {
		margin-bottom: 10px;
		font-size: 14px;
		color: #e5e1e1;
		line-height: 2;

		.content {
			overflow: hidden;
		}

		.operator-icon {
			float: left;
			margin: 0 10px 0 0;
		}
	}

	.block-content {
		.feature {
			margin-bottom: 15px;
			font-size: 14px;
			line-height: 1.57;
			color: $main-color;
		}

		.level-top {
			font-size: 14px;
			font-weight: normal;
			color: $main-color;
			display: block;
			display: inline-block;
			line-height: 16px;
			margin: 0 0 28px;
			position: relative;
			transition: color 0.3s;

			&:before {
				content: '';
				width: 8px;
				height: 8px;
				box-sizing: border-box;
				border: 2px solid rgba(0, 0, 0, 0.3);
				border-radius: 50%;
				position: absolute;
				top: 50%;
				margin-top: -4px;
				left: -24px;
				opacity: 0;
				transition: opacity 0.3s;
			}

			&:hover {
				color: #696969;

				&:before {
					opacity: 1;
				}
			}
  		}

		.footer-logo {
			display: block;
			margin: 0 0 45px;
		}

	}


	.footer-contacts-holder {
		margin-bottom: 15px;
	}

	.callback-footer {
		font-size: 14px;
		line-height: 1.57;
		color: $link;

		&:hover {
			color: $link_hover;
		}
	}

	.block-title {
		cursor: default;
		font-size: 24px;
		font-weight: 300;
		color: $main-color;
		margin-bottom: 50px;
	}

	.footer-about {
		.block-title {
			font-size: 14px;
			font-weight: 300;
			line-height: 1.57;
			color: rgba(0, 0, 0, 0.7);
			margin-bottom: 10px;
		}

		.footer-block {
			margin-top: 30px;

			.block-footer-contacts {
				font-size: 14px;
				line-height: 1.57;
				color: $main-color;
			}
		}
	}

	@include bp(max-width, $bp-tiny) {
		text-align: center;

		/* .collapsible {
			margin: 0 -30px;
		} */

		.block-title {
			/* padding: 40px 0 30px;
			margin: 0; */

			/* > span {
				margin-right: 5px;
			} */

			&.active {
				//background: $darkest;

				& + .block-content {
					//background: $darkest;
					//padding-bottom: 30px;
				}
			}
		}

		.block-footer-contacts {
			.operator-icon {
				float: none;
			}

			.content {
				display: inline;
			}
		}

		.footer-logo {
			margin: 0 auto 40px;
		}

		.block-footer-contacts {
			font-size: 16px;
		}

		.callback-footer {
			display: inline-block;
			vertical-align: top;
			margin-bottom: 40px;

			@include bp(max-width, $bp-smallest) {
				margin-bottom: 20px;
			}
		}

		.level-top {
			font-size: 16px;
			line-height: 2;
		}
	}

	.footer-catalog {
		box-sizing: border-box;
	}

	#subscribe-form {
		padding-top: 88px;

		label[for="newsletter"] {
			display: block;
			margin-right: 0;
			font-size: 24px;
			font-weight: 300;
			color: $main-color;
		}

		.input-text {
			width: 100%;
			height: 36px;
			background: none;
			border-width: 0 0 1px;
			border-color: rgba(0, 0, 0, 0.1);
			opacity: 0.7;
			font-size: 12px;
			color: rgba(125, 125, 125, 0.7);
			font-weight: normal;

			&:focus {
				color: #000;
				border-color: #000;
				box-shadow: none;
			}
		}

		.button {
			background: none;
			border: none;
			padding: 0;
			font-size: 14px;
			line-height: 22px;
			color: $link;
			text-transform: none;
			margin: 24px 0 0;
			height: auto;
			text-align: left;

			&:hover {
				color: $link_hover;
			}
		}
	}

	.footer-social {
		@include bp(max-width, $bp-tiny) {
			.block-title {
				padding-bottom: 0;
			}
		}
	}

	.footer-social {
		margin: 0;
		width: 25%;
	}

	.social-links {
		padding: 0;
		font-size: 0;
		margin: 0 -8px;

		a {
			display: inline-block;
			padding: 0 8px;
			margin-bottom: 8px;

			.ic {
				margin: 0;
				width: 50px;
				height: 50px;
				font-size: 52px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-color: #dcdcdc;
				transition: all 0.3s;
			}

			&:hover {
				.ic {
					border-color: #fff;
					background: #fff;
					box-shadow: 0 10px 30px 0 rgba(202, 202, 202, 0.7);
				}
			}
		}
	}
}

.footer-bottom-container {
	border-top: 1px solid rgba(0, 0, 0, 0.1);

	.footer-copyright {
		font-size: 14px;
		font-weight: normal;
		line-height: 22px;
		color: $main-color;
	}

	.developed-by {
		.ic-brander {
			@include icon;
			margin-left: 5px;
			font-size: 12px;

			&:before {
				content: $brander;
			}
		}

		@include bp(max-width, $bp-tiny){
			padding-top: 10px;
			text-align: center;
		}
	}
}

.footer-bottom {
	.item-right {
		text-align: right;
	}

	@include bp(max-width, $bp-tiny) {
		.item {
			float: none;
			width: 100%;
		}
	}
}


@include bp(max-width, $bp-tiny){
	.footer-primary-container {
		padding-top: 0;

		.footer-primary-top {
			margin: 0 -20px;
		}

		.block-content {
			.footer-logo {
				margin-bottom: 40px;
			}
		}

		.mobile-collapsible {
			max-width: 320px;
			margin: 0 auto -1px;
			border: 1px solid rgba(0, 0, 0, 0.1);
			border-width: 1px 0;

			.block-title {
				padding: 0;
				text-transform: uppercase;
				font-size: 14px;
				font-weight: normal;
				line-height: 22px;
				padding: 19px 20px;
				margin: 0;
				color: $main-color;
				text-align: left;

				&:after {
					@include icon;
					content: $arrow_down;
					float: right;
				}
			}

			.block-content {
				padding: 0 20px;

				> div {
					text-align: left;

					a {
						line-height: 50px;
						margin: 0;

						&:before {
							display: none;
						}
					}

					&:not(:first-child) {
						border-top: 1px solid rgba(0, 0, 0, 0.1);
					}
				}
			}
		}

		.footer-about {
			//border-bottom: 1px solid rgba(0, 0, 0, 0.1);

			.block-title {
				padding: 0 0 10px;
			}

			.feature {
				max-width: 200px;
				margin: 0 auto 15px;
			}

			.footer-block {
				max-width: 200px;
				margin: 30px auto 15px;
			}
		}

		.footer-social {
			max-width: 280px;
			margin: 0 auto;
			float: none;
			clear: both;

			.block-title {
				font-size: 22px;
				font-weight: normal;
				line-height: 28px;
				padding: 48px 0 24px;
				margin: 0;
				text-align: left;
			}

			.social-links {
				a {
					padding: 0 3px;
					padding-bottom: 4px;
				}
			}
		}

		#subscribe-form {
			padding: 45px 0 0;
			width: 100%;

			label[for="newsletter"] {
				text-align: left;
				margin-bottom: 24px;
			}

			.input-box {
				float: none;
				text-align: left;

				input {
					padding: 0;
				}
			}
		}
	}
}