.brander-unitopblog-post-view {
  .post-post_image {
	padding: 20px 0;

	img {
	  width: 100%;
	  height: auto;
	  border: 1px solid #fceee3;
	}
  }

  .post-preview_image {
	float: left;
	width: 364px;
	margin: 0 20px 20px 0;
	border: 1px solid #fceee3;
	box-shadow: 13px 13px 0 #f2e6e2;

	& + .post-details {
	  float: left;
	  width: calc(100% - 400px);
	  padding-left: 74px;

	  @include bp(max-width, $bp-small) {
		width: 100%;
		padding-left: 0;
	  }
	}

	img {
	  width: 100%;
	  height: auto;
	}

	@include bp(max-width, $bp-small) {
	  float: none;
	  width: 100%;
	  margin: 0 auto 40px;
	}
  }

  .post-details {
	font-size: 1rem;
	box-sizing: border-box;

	@include bp(max-width, 991px) {
	  padding-left: 20px;
	}

	@include bp(max-width, $bp-tiny) {
	  padding: 40px 0 0;
	}

	p {
	  text-align: justify;
	  text-indent: 20px;
	}
  }

  .post-title {
	margin-bottom: 40px;
	overflow: hidden;

	h1 {
		min-height: 0;
		font-weight: 300;
		line-height: 1.27;
		color: $main-color;
	}
  }

  .post-info {
	font-size: 14px;
	font-weight: 300;
	line-height: 1.29;
	color: $main-color;

	.post-post_date {
		margin-bottom: 15px;
		opacity: 0.4;
	}

	.author_name {
		font-size: 16px;
		opacity: 1;
	}
  }

  .post-post_content {
	margin-bottom: 60px;
	font-size: 14px;
	font-weight: normal;
	line-height: 1.85;
	color: $main-color;

	p {
	  margin-bottom: 38px;

	  &:last-child {
		margin-bottom: 0;
	  }
	}
  }

  .box-collateral {
	margin-bottom: 60px;
  }

  .form-add {
	margin-bottom: 60px;
	text-align: center;

	h2 {
		//font-size: 23px;
		font-weight: 300;
		margin: 0 0 30px;
	}

	.form-list {
		label {
			font-size: 14px;
			font-weight: 300;
			color: $main-color;
		}
	}
  }

  h2 {
	text-align: center;
  }

  #comment-form {
	.buttons-set {
	  border: none;
	  margin: 0;
	  padding: 0;

	  button {
	  	float: left;
	  }
	}
  }
}

.post-list {
  .item {
	img {
	  border: 1px solid #fceee3;
	  @include trs(border-color .3s ease);
	}

	&:hover {
	  img {
		border-color: $main-color;
	  }
	}
  }
}