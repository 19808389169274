.catalog-product-view {
  .breadcrumbs {
    margin-bottom: 40px;

    @include bp(max-width, $bp-tiny){
      margin: 20px 0 30px;
      text-align: left;
    }
  }

  .main {
    .inner-container {
      overflow: hidden;
    }
  }
}

.product-view {
  &,
  > form {
    margin: 0;
  }

  > form {
    margin: 0 0 70px;
    @include cleaner;

    @include bp(max-width, $bp-tiny){
      margin: 0;
    }
  }

  .product-img-column {
    width: 28.9565%;
  }

  .product-primary-column {
    width: 45.305%;
    padding: 0 3.831%;
  }

  .product-secondary-column {
    width: 25.7385%;

    .inner {
      padding: 0;
    }
  }

  .product-img-column,
  .product-primary-column,
  .product-secondary-column {
    float: left;
    margin: 0;
    box-sizing: border-box;
  }

  .product-shop-heading {
    margin-bottom: 25px;

    .product-name {
      margin: 0 0 5px;

      h1 {
        font-size: 28px;
        font-weight: 300;
        line-height: 1.14;
        color: $main-color;
        margin: 0;
        text-transform: none;

        @include bp(max-width, $bp-tiny){
          font-size: 22px;
          font-weight: normal;
          line-height: 1.27;
        }
      }
    }

    .sku {
      margin: 0;
      opacity: 0.5;
      font-size: 13px;
      font-weight: 300;
      line-height: 1.38;
      color: $main-color;

      .label {
        font-weight: unset;
      }
    }
  }

  .product-img-column {
    #cloud-zoom-big {
      background-color: #fff;
    }

    .img-box {
      .product-image {
        position: relative;
        border: 1px solid #dedede;
        margin: 0 auto;
        padding: 17px;
        box-sizing: border-box;
        margin-bottom: 20px;
      }

      .zoom-btn-small {
        font-size: 0;
        width: 50px;
        height: 50px;
        bottom: 17px;
        right: 17px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
          @include icon;
          content: $search;
          font-size: 24px;
        }

        &:hover {
          color: #dedede;
        }
      }

      .more-images {
        .item {
          margin: 0 10px;

          a {
            position: relative;
            border: 1px solid #dedede;
            background: rgba(0, 0, 0, 0.05);

            &:after {
              content: '';
              position: absolute;
              left: 0;
              bottom: -1px;
              width: 0;
              height: 4px;
              background: #e0e0e0;
              transition: width 0.3s;
            }

            &.active {
              background: #fff;
              
              &:after {
                background: #a9f2ff;
              }
            }

            &:hover,
            &.active {
              //background: rgba(0, 0, 0, 0.05);

              &:after {
                width: 100%;
              }
            }
          }
        }

        .owl-wrapper-outer {
          position: relative;
          z-index: 2;
        }

        .owl-controls {
          .owl-buttons {
            top: 0;
            left: -28.75%;
            width: 157.5%;
            height: 100%;

            .owl-prev,
            .owl-next {
              width: 15.875%;
              height: 100%;
              border-radius: 0;
              border: none;
              font-size: 48px !important;

              &:hover {
                background: none !important;
              }
            }
          }
        }
      }

      .itemslider-thumbnails {
        max-width: 63.465%;

        &.count-2 {
          max-width: 42.31%;
        }

        &.count-1 {
          max-width: 21.158%;
        }
      }
    }
  }


  .product-primary-column {
    .ratings {
      display: none;
    }

    .short-description {
      font-size: 16px;
      font-weight: 300;
      color: $main-color;
      margin: 30px 0;
    }

    .product-type-data {
      margin: 0;
    }

    .container1-wrapper {
      margin: 0 0 30px;
      padding: 0;
      border: none;

      .product-options {
        max-width: 455px;
        margin-bottom: 30px;

        select {
          & + .chosen-container {
            width: 100% !important;
          }
        }

        .required {
          display: none;
        }

        .feature_item {
          dt {
            margin-bottom: 8px;

            .weight,
            label {
              font-size: 13px;
              font-weight: 300;
              line-height: 1.38;
              color: $main-color;

              .select-label {
                font-weight: 500;
              }
            }

            label {
              float: left;
            }

            .weight {
              float: right;
            }
          }

          dd {
            .configurable-swatch-list {
              @include cleaner;

              li {
                float: left;
                margin: 0 -1px -1px 0;
                border: 1px solid #dedede;
                position: relative;

                .swatch-link {
                  margin: 0;
                  border: 1px solid transparent;
                  font-size: 14px;
                  font-weight: normal;
                  color: $main-color;

                  .swatch-label {
                    border: none;
                    background: none;
                    width: 62px;
                    height: 36px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }

                &.hover {
                  background: #f2f2f2;
                }
                &.selected {
                  color: #23c7e4;
                  z-index: 2;

                  &,
                  .swatch-link {
                    border-color: #44d6f0;
                    color: #44d6f0;
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }
      }
    }

    .price-box {
      margin: 0 0 25px;
      padding: 0;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;

      .price-as-configured,
      .regular-price,
      .special-price,
      .old-price {
        margin: 0;

        .price {
          color: $main-color;
        }
      }

      .price-as-configured,
      .regular-price,
      .special-price {
        margin-right: 25px;
        line-height: 22px;

        .price {
          font-size: 22px;
          font-weight: 500;
          line-height: 22px;
        }
      }

      .regular-price,
      .special-price {
        .price-label {
          display: none;
        }
      }

      .price-as-configured {
        .price-label {
          font-size: 16px;
          line-height: 22px;
          font-weight: normal;
          text-transform: none;
          color: $main-color;
        }
      }

      .old-price {
        .price {
          opacity: 0.3;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    .add-to-box {
      margin: 0;
      clear: both;
      display: flex;
      align-items: center;
    }

    .add-to-cart {
      margin-right: 15px;

      .button-cart {
        height: 48px;
        line-height: 48px;
        border-radius: 24px;
        font-size: 16px;
      }
    }

    .qty-wrapper {
      display: block;
      margin-bottom: 20px;

      & ~ .button-cart {
        float: none;
      }
    }

    .btns-set {
      a {
        @extend %btn-light;
        min-width: 192px;
      }
    }

    .action-box {
      margin: 30px 0 0;

      .add-to-links {
        li {
          margin-right: 15px;

          [class^="link-"] {
            margin: 0;
            width: 48px;
            height: 48px;
            cursor: pointer;

            .label {
              display: none;
            }

            .ic {
              font-size: 32px;
              width: 100%;
              height: 30px;
              color: inherit;
            }
          }
        }
      }
    }
  }

  .product-secondary-column {
    .benefits-container {
      border-left: 1px solid #dedede;
      margin-bottom: 25px;

      .benefits-grid {
        padding-left: 40px;

        .benefit {
          margin-bottom: 20px;

          &,
          > a {
            padding: 0;
            @include cleaner;
          }

          span {
            display: block;
          }

          .img-wrap {
            width: 26px;
            height: 26px;
            float: left;
            padding-top: 6px;
          }

          .benefits-info {
            .benefit-title {
              margin-top: 15px;
              font-size: 14px;
              font-weight: normal;
              line-height: 1.29;
              color: #23c7e4;
              border-bottom: 1px dashed;
              display: inline-block;

              &:hover {
                border-color: transparent;
              }
            }
          }

          .img-wrap ~.benefits-info {
            width: calc(100% - 26px);
            float: left;
            padding-left: 16px;
            padding-top: 9px;
            box-sizing: border-box;

            .benefit-title {
              font-size: 14px;
              font-weight: normal;
              line-height: 18px;
              color: $main-color;
              margin-bottom: 3px;
              text-transform: none;
              display: block;
              border: none;
              margin-top: 0;
            }

            .benefit-text {
              font-size: 13px;
              font-weight: 300;
              line-height: 1.54;
              color: $main-color;
            }
          }
        }
      }
    }

    .action-box {
      margin: 0 0 25px;
      padding-left: 40px;
    }

    .feature-wrapper {
      margin: 0;
      padding: 0;
      border: none;
    }
  }


  @include bp(max-width, $bp-small){
    .product-img-column {
      width: 38.69%;
    }

    .product-primary-column {
      width: 61.31%;
    }

    .product-secondary-column {
      width: 100%;

      .benefits-container {
        border: none;

        .benefits-grid {
          padding: 0;
          display: flex;
          flex-wrap: wrap;
          border: 1px solid rgba(0, 0, 0, 0.1);

          .benefit {
            width: 50%;
            padding: 20px;
            margin: 0;
            box-sizing: border-box;

            &:nth-child(1),
            &:nth-child(3) {
              border-right: 1px solid rgba(0, 0, 0, 0.1);
            }

            &:nth-child(1),
            &:nth-child(2) {
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }

    .product-img-column,
    .product-primary-column,
    .product-secondary-column {
      margin-bottom: 30px;
    }
  }

  @include bp(max-width, $bp-tiny){
    .product-img-column {
      width: 100%;

      .img-box {
        max-width: 370px;
        margin: 0 auto;

        .product-image {
          width: 100%;
          padding: 13px;
        }

        .more-images {
          .owl-controls {
            .owl-buttons {
              display: block;
            }
          }
        }
      }
    }

    .product-primary-column {
      width: 100%;
      padding: 0;
      padding-bottom: 25px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .container1-wrapper {
        max-width: 370px;
        margin: 0 auto;

        .product-options {
          .feature_item {
            .swatch-attr {
              margin-bottom: 15px;
            }

            dd {
              .configurable-swatch-list {
                li {
                  width: 25%;
                  box-sizing: border-box;

                  .swatch-link {
                    .swatch-label {
                      width: 100%;
                      height: 48px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .product-options-bottom,
      .add-to-box {
        flex-direction: column;
        margin-bottom: 10px;

        .qty-wrapper {
          text-align: center;
        }

        .add-to-cart,
        .btns-set {
          width: 100%;
          margin: 0 0 15px;

          .button-cart,
          .fancybox {
            width: 100%;
          }

          .button-cart {
            font-size: 16px;
          }
        }
      }

      .action-box {
        .add-to-links {
          text-align: center;

          li {
            margin: 0 12px;
          }
        }
      }
    }

    .product-secondary-column {
      .inner {
        max-width: 370px;
        margin: 0 auto;
      }

      .benefits-container {
        padding-bottom: 5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        
        .benefits-grid {
          display: block;
          border: none;

          .benefit {
            width: 100%;
            padding: 0;
            margin: 0 0 25px;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              border: none;
            }
          }
        }
      }

      .action-box {
        padding: 0;

        .social-links {
          .social-list {
            text-align: center;

            li {
              margin: 0 15px;
            }
          }
        }
      }
    }
  }




  #product-tabs {
    margin-bottom: 100px;

    .tabs {
      width: 100%;
      position: relative;
      background: none;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      li {
        display: inline-block;
        float: none;
        margin: 0 40px;
      }

      a {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.57;
        color: $main-color;
        background: none;
        padding: 17px 0;
        text-transform: uppercase;

        &:after {
          position: absolute;
          content: '';
          opacity: 0.15;
          width: 0;
          height: 4px;
          background: $main-color;
          left: 0;
          bottom: -1px;
          @include trs(width .3s ease);
        }

        &.current {
          &:after {
            opacity: 1;
            background: #a9f2ff;
          }
        }

        &.current,
        &:hover {
          &:after {
            width: 100%;
          }
        }
      }
    }

    .tabs-panels {
      background: none;
      padding: 60px 0 30px;

      .panel {
        .tabs-description,
        .box-reviews,
        .tabs-data-table {
          margin: 0 auto;
        }

        .tabs-description {
          max-width: 900px;
          font-size: 13px;
          font-weight: normal;
          line-height: 1.85;
          color: $main-color;

          a {
            color: $link;

            &:hover {
              color: $link_hover;
            }
          }

          p,
          ul,
          li {
            margin-bottom: 25px;
          }
        }

        .box-reviews {
          max-width: 660px;

          dt {
            padding: 20px 0 0;
          }

          dd {
            padding: 0 0 45px;
            margin: 0 0 25px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }

          .customer_heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 14px;

            .customer_name {
              font-size: 18px;
              font-weight: normal;
              line-height: 1.22;
              color: $main-color;
            }
            .date {
              font-size: 13px;
              font-weight: 300;
              line-height: 1.38;
              color: $main-color;
            }
          }

          .ratings-table {
            margin: 0 0 28px;
          }

          .detail-block {
            font-size: 13px;
            font-weight: normal;
            line-height: 1.85;
            color: $main-color;
          }

          .form-add {
            margin-top: 65px;

            h2 {
              font-size: 28px;
              font-weight: 300;
              color: $main-color;
              margin-bottom: 45px;
            }

            #product-review-table {
              display: block;
              margin-bottom: 35px;

              th,
              td {
                display: inline-block;
                vertical-align: middle;
                padding: 0;
              }

              .label {
                width: 100px;
                display: inline-block;
                vertical-align: middle;
                font-size: 13px;
                font-weight: normal;
                line-height: 28px;
                color: $main-color;
              }

              .value {
                width: 20px;
                height: 20px;
                padding: 0;
                vertical-align: middle;
                margin: 0 1px;
                position: relative;

                &.active,
                &.current {
                  input[type="radio"] {
                    &+label {
                      &:before {
                        color: #ff7d36;
                      }
                    }
                  }
                }
              }

              input[type="radio"] {
                visibility: hidden;
                opacity: 0;

                &+label {
                  cursor: pointer;

                  &:before {
                    @include icon;
                    content: $star;
                    position: absolute;
                    font-size: 20px;
                    line-height: 1;
                    color: #dedede;
                    left: 0;
                    top: 0;
                  }
                }

                /* &:checked {
                  &+label {
                    &:before {
                      content: '\e224';
                    }
                  }
                } */
              }
            }

            .reviews-list {
              margin: 0;

              li {
                margin: 0 0 35px;
              }

              label {
                width: 100px;
                font-size: 13px;
                font-weight: normal;
                line-height: 28px;
                color: $main-color;
              }

              .review-text {
                padding: 10px 0;
                
                & + .nobr {
                  font-style: italic;
                }
              }

              .input-box {
                width: calc(100% - 100px);
                float: left;
                clear: none;

                textarea,
                input {
                  display: block;
                  border: 1px solid rgba(0, 0, 0, 0.1);
                  border-width: 0 0 1px;
                  height: 28px;
                  resize: none;
                  padding: 6px 0;
                  font-family: 'GothamPro';
                  font-size: 14px;
                  font-weight: normal;
                  color: $main-color;
                  box-sizing: border-box;
                }

                textarea {
                  height: 80px;
                }

                input {
                  max-width: 240px;
                }
              }
            }

            .buttons-set {
              padding: 0;

              .button {
                min-width: 160px;
                float: left;
                margin-left: 100px;
                height: 48px;
                line-height: 48px;
                border-radius: 24px;
                font-size: 16px;
              }
            }
          }
        }

        .tabs-data-table {
          max-width: 680px;
          border: 1px solid #dedede;
          padding: 50px 80px;
          box-sizing: border-box;

          tr {
            td {
              height: 44px;
              padding: 10px 0;
              font-size: 14px;
              font-weight: 300;
              line-height: 1.29;
              color: $main-color;

              &:last-child {
                font-weight: 500;
              }
            }

            &:not(:first-child) {
              td {
                border-top: 1px solid rgba(0, 0, 0, 0.1);
              }
            }
          }
        }
      }

      .acctab {
        font-size: 14px;
        font-weight: normal;
        text-transform: uppercase;
        line-height: 1;
        padding: 23px 15px 22px;
        color: $main-color;
        margin: 0 -15px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: none;

        &:after {
          @include icon;
          content: $arrow_down;
          float: right;
          font-size: 16px;
        }
      }
    }

    &.accor {
      .tabs-panels {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .panel {
          padding: 0;

          .tabs-data-table {
            padding: 0;
            border: none;
          }

          .box-reviews {
            .form-add {
              h2 {
                font-size: 22px;
              }
            }

            .form-add {
              .buttons-set {
                .button {
                  @include bp(max-width, $bp-smallest){
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#review-form {
  thead,
  tbody,
  td {
    border: none;
  }

  thead {
    display: none;
  }
}



.box-additionals-holder {
  display: block;
  @include cleaner;

  .box-additional {
    display: block;
    margin: 0;
    position: relative;

    &.box-sidebar {
      box-sizing: border-box;
    }
  }
}

.accor {
  .acctab {
    +.panel {
      display: none !important;
    }

    &.active {
      &+.panel {
        display: block !important;
      }
    }
  }
}



.gen-tabs .acctab {
  &:hover,
  &:focus,
  &.active {
    border-color: $main-color;
  }
}


.itemslider-related-mobile-wrapper {
  display: none;

  @include bp(max-width, 959px) {
    display: block;
  }
}

.product-shop-heading {
  @include cleaner;
}


#srka-product-review-list .srka-rating-code .label {
  float: none;
  display: inline-block;
  vertical-align: middle;
  width: 110px;

  &:after {
    content: ' *';
    color: #EE001C;
    font-weight: normal;
    font-family: Verdana, Tahoma, Arial, sans-serif;
    font-size: 12px;
  }

  & + .options {
    display: inline-block;
    vertical-align: middle;
    padding-top: 0;
  }
}

#oneclickbuy_form {
  width: 510px;
  max-width: 100%;
  padding: 40px;
  box-sizing: border-box;

  .popup-heading {
    h2 {
      color: #42536a;
      text-align: center;
      font-family: 'Open Sans', arial;
      margin: 0 0 10px;
      font-size: 36px;
      line-height: 1.1;
    }
  }

  .popup-content {
    .row {
      .validation-advice {
        padding: 0;
        text-align: center;
        width: 264px;
        max-width: 100%;
        margin: 5px auto 0;
        color: #f68d91;
      }

      input {
        height: 44px;
        width: 264px;
        max-width: 100%;
        display: block;
        margin: 0 auto;
        border: none;
        border-bottom: 1px solid #c6cbd2;
        font-size: 14px;

        &:focus {
          box-shadow: none !important;
          border-color: $main-color;
        }

        &.validation-failed {
          border: none !important;
          border-bottom: 1px solid #ed1c24 !important;
        }
      }
    }

    .btn-holder {
      padding-top: 37px;

      .button {
        display: block;
        margin: 0 auto;
        float: none;
      }
    }
  }
}